import invariant from 'tiny-invariant';
import { dayjs, formatUrlDate, relativeDateString } from '../utils';
import { useMemo } from 'react';
import { useParams } from 'react-router';

class RelativeDateGenerator {
  constructor(base) {
    invariant(dayjs.isDayjs(base) && base.isValid(), 'Expected a valid date');
    this._base = this._makeDetails(dayjs(base));
    this._today = this._makeDetails(dayjs());
  }

  get base() {
    return this._base;
  }

  get today() {
    return this._today;
  }

  at(offset, unit = 'days') {
    return this._makeDetails(this.base.t.add(offset, unit));
  }

  _makeDetails(t) {
    return {
      name: relativeDateString(t),
      short: relativeDateString(t),
      t,
      href: `../${formatUrlDate(t)}`,
    };
  }
}

const URL_DATE_REGEX = /20\d{6}/;

export function useRelativeDateGenerator(date) {
  return useMemo(() => {
    invariant(typeof date === 'string' && URL_DATE_REGEX.test(date), 'Expected a date string');
    return new RelativeDateGenerator(dayjs(date));
  }, [date]);
}

export function useRelativeDateGeneratorFromUrl(param = 'date') {
  const params = useParams();
  let date = useMemo(() => {
    const str = params[param];
    if (URL_DATE_REGEX.test(str)) {
      return str;
    } else {
      const t = dayjs();
      if (str === 'yesterday') {
        return formatUrlDate(t.subtract(1, 'day'));
      } else if (str === 'tomorrow') {
        return formatUrlDate(t.add(1, 'day'));
      } else {
        return formatUrlDate(t);
      }
    }
  }, [param, params]);
  return useRelativeDateGenerator(date);
}
