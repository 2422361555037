import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldPlain } from '../../../lib/components/form/text-field';
import { TextareaFieldPlain } from '../../../lib/components/form/textarea-field';
import { NumberFieldPlain } from '../../../lib/components/form/number-field';
import { CheckBox } from '../../../lib/components/form/check-box';
import { RadioGroup } from '../../../lib/components/form/radio-group';
import { ListBox, ListBoxPlain } from '../../../lib/components/form/list-box';
import { ComboBox, ComboBoxPlain } from '../../../lib/components/form/combo-box';
import { Children, cloneElement, useId, useMemo } from 'react';
import { yup } from '../../../lib/utils';
import { Button } from '../../../lib/components/button';
import { BeakerIcon, BoltIcon } from '@heroicons/react/20/solid';
import { CloudIcon, UserIcon } from '@heroicons/react/24/outline';
import {
  TOAST_INSTANCE_SCHEMA,
  successToast,
  useGenericErrorsToast,
} from '../../../lib/components/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldDecoration } from '../../../lib/components/form/field-decoration';
import clsx from 'clsx';

function Debug() {
  return (
    <div className="h-full max-h-full overflow-scroll px-1">
      <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 mb-4">
        Debug Page
      </h1>
      <p>
        A collection of various tests such as API calls, component tests such as form elements and
        whatever else the devs deem useful.
      </p>
      <ShadingTestSection />
      <ButtonTestSection />
      <TextFieldTest />
      <NumberFieldTest />
      <TextareaFieldTest />
      <CheckBoxTest />
      <RadioGroupTest />
      <ListBoxTest />
      <ComboBoxTest />
    </div>
  );
}

function ShadingTestSection() {
  return (
    <section className="mt=6 border-l-4 border-gray-200 px-3">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10 mb-4">
        Shading
      </h2>
      <div className="flex space-x-4 text-xs uppercase">
        <div className="shaded-pink rounded p-2">Pink</div>
        <div className="shaded-pink rounded border p-2">Pink</div>
        <div className="shaded-red rounded p-2">Red</div>
        <div className="shaded-red rounded border p-2">Red</div>
        <div className="shaded-orange rounded p-2">Orange</div>
        <div className="shaded-orange rounded border p-2">Orange</div>
        <div className="shaded-green rounded p-2">Green</div>
        <div className="shaded-green rounded border p-2">Green</div>
        <div className="shaded-violet rounded p-2">Violet</div>
        <div className="shaded-violet rounded border p-2">Violet</div>
        <div className="shaded-blue rounded p-2">Blue</div>
        <div className="shaded-blue rounded border p-2">Blue</div>
        <div className="shaded-gray rounded p-2">Gray</div>
        <div className="shaded-gray rounded border p-2">Gray</div>
      </div>
    </section>
  );
}

function ButtonTestSection() {
  const showToast = () => successToast(<ToastContent />);
  const genericErrorToast = useGenericErrorsToast();
  return (
    <section className="mt=6 border-l-4 border-gray-200 px-3 space-y-4">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10">
        &#60;Button /&#62;
      </h2>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Filled" onClick={showToast} size="small" />
        <Button
          label="Icon only"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
          size="small"
        />
        <Button label="Filled" onClick={showToast} />
        <Button label="Icon only" labelClassName="sr-only" icon={BoltIcon} onClick={showToast} />
        <Button label="Standard" disabled />
        <Button label="Icon only" labelClassName="sr-only" icon={BeakerIcon} disabled />
        <Button label="Standard" size="large" />
        <Button label="Zap" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button
          label="Outline"
          variant="outline"
          onClick={() => genericErrorToast('Test', new Error('test'))}
        />
        <Button
          label="Icon only"
          variant="outline"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="outline" disabled />
        <Button
          label="Icon only"
          variant="outline"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="outline" size="large" />
        <Button label="Zap" variant="outline" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Plain" variant="plain" onClick={showToast} />
        <Button
          label="Icon only"
          variant="plain"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="plain" disabled />
        <Button
          label="Icon only"
          variant="plain"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="plain" size="large" />
        <Button label="Zap" variant="plain" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Filled" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" color="gray" disabled />
        <Button
          label="Icon only"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" color="gray" size="large" />
        <Button label="Zap" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Outline" variant="outline" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          variant="outline"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="outline" disabled />
        <Button
          label="Icon only"
          variant="outline"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="outline" color="gray" size="large" />
        <Button label="Zap" variant="outline" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Plain" variant="plain" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          variant="plain"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="plain" color="gray" disabled />
        <Button
          label="Icon only"
          variant="plain"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="plain" color="gray" size="large" />
        <Button label="Zap" variant="plain" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
    </section>
  );
}

function ToastContent({ instance }) {
  return (
    <>
      <p className="font-medium text-gray-900">New booking for VRIES, Victor created!</p>
      <p className="mt-2 text-sm text-gray-500">
        Colonosopy & Gastroscopy with Dr. Ogra on Tue, 20 Sep 2023 at 15:00
      </p>
      <p className="mt-2 text-sm text-medium">
        <button className="text-indigo-600 hover:underline" onClick={instance.close}>
          View list
        </button>
      </p>
    </>
  );
}

ToastContent.propTypes = {
  instance: TOAST_INSTANCE_SCHEMA.required().pt(),
};

function FromElementTestSection({ type, schema = yup.mixed(), noDecorate = false, children }) {
  const id = useId();
  const child = Children.only(children);
  const name = type ?? child.type.name;

  const formSchema = yup.object({
    disabled: yup.boolean().default(false),
    decorate: yup.boolean().default(noDecorate),
    testValue: schema,
  });

  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: formSchema.validateSync({}),
    mode: 'onChange',
  });

  const { testValue, decorate, ...elementProps } = form.watch();
  const testValueString = testValue?.toString() ? testValue.toString() : '<empty>';

  const testElement = useMemo(() => {
    const plain = cloneElement(child, { field: 'testValue', ...elementProps });
    if (decorate && !noDecorate) {
      return (
        <FieldDecoration
          {...elementProps}
          field="testValue"
          label={`Label for ${name} element`}
          hint="Need a hint?"
          help="With the help prop you can set some guidelines for the user."
        >
          {plain}
        </FieldDecoration>
      );
    } else {
      return plain;
    }
  }, [decorate, child, elementProps, name, noDecorate]);

  return (
    <section className="mt=6 border-l-4 border-gray-200 px-3">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10 mb-4">
        &#60;{name} /&#62;
      </h2>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => form.reset())}>
          <div>{testElement}</div>
          <div className="flex mt-4 p-2 text-sm item-center flex-row space-x-8 bg-gray-200/50 border border-dashed border-gray-300">
            <div>
              <input
                type="checkbox"
                className="mr-2"
                id={`${id}-disabled`}
                {...form.register('disabled')}
              />
              <label htmlFor={`${id}-disabled`}>Disabled</label>
            </div>
            <div className={clsx({ hidden: noDecorate })}>
              <input
                type="checkbox"
                className="mr-2"
                id={`${id}-decorate`}
                {...form.register('decorate')}
              />
              <label htmlFor={`${id}-decorate`}>Decorate</label>
            </div>
            <div>
              <span className="pr-1">Value:</span>
              <b>{testValueString}</b>
            </div>
          </div>
        </form>
      </FormProvider>
    </section>
  );
}

FromElementTestSection.propTypes = {
  type: yup.string(),
  children: yup.mixed().react().required().pt(),
  schema: yup.mixed().required(),
  noDecorate: yup.boolean().pt(),
};

const TEXT_FIELD_SCHMEA = yup.string().max(10);

function TextFieldTest() {
  return (
    <FromElementTestSection type="TextField" schema={TEXT_FIELD_SCHMEA}>
      <TextFieldPlain placeholder="Input your name here" />
    </FromElementTestSection>
  );
}

const NUMBER_FIELD_SCHMEA = yup.number().positive();

function NumberFieldTest() {
  return (
    <FromElementTestSection type="NumberField" schema={NUMBER_FIELD_SCHMEA}>
      <NumberFieldPlain min={-10} max={20} placeholder="Days" />
    </FromElementTestSection>
  );
}

const TEXTAREA_FIELD_SCHMEA = yup.string().test({
  name: 'words',
  message: '${path} must be less than 10 words',
  test: (value) => !(value?.split(/\s+/)?.length > 10),
});

function TextareaFieldTest() {
  return (
    <FromElementTestSection type="TextareaField" schema={TEXTAREA_FIELD_SCHMEA}>
      <TextareaFieldPlain field="test" placeholder="Input your text here" />
    </FromElementTestSection>
  );
}

const CHECK_BOX_FIELD_SCHMEA = yup.boolean().default(true);

function CheckBoxTest() {
  return (
    <FromElementTestSection type="CheckBox" schema={CHECK_BOX_FIELD_SCHMEA} noDecorate>
      <CheckBox
        label={`Label for ${name} element`}
        description="With the help prop you can set some guidelines for the user."
      />
    </FromElementTestSection>
  );
}

const RADIO_GROUP_FIELD_SCHMEA = yup.string().matches(/option/);

function RadioGroupTest() {
  return (
    <FromElementTestSection type="RadioGroup" schema={RADIO_GROUP_FIELD_SCHMEA}>
      <RadioGroup
        options={[
          <RadioGroup.LabelItem
            key="option-a"
            label="Option A"
            description="This is maybe not the option for you"
          />,
          <RadioGroup.LabelItem
            key="option-b"
            label="Option B"
            description="Choose this because B stands for better"
          />,
          <RadioGroup.LabelItem key="option-c" label="Option C" />,
          <RadioGroup.Item key="jess">
            <div className="flex flex-col items-center w-14">
              <img
                src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                className="h-8 w-8 flex-shrink-0 rounded-full"
                alt=""
              />
              <span className="mt-2 text-gray-900">Jess</span>
            </div>
          </RadioGroup.Item>,
        ]}
      />
    </FromElementTestSection>
  );
}

const LIST_BOX_SCHEMA = yup.string().matches(/-a$/, 'You should select Jess');

function ListBoxTest() {
  return (
    <FromElementTestSection type="ListBox" schema={LIST_BOX_SCHEMA}>
      <ListBoxPlain
        field="test"
        placeholder="Select one of the options"
        options={[
          <ListBox.Item key="item-a">
            <div className="flex flex-row items-center">
              <img
                src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                className="h-6 w-6 flex-shrink-0 rounded-full"
                alt=""
              />
              <span className="ml-2 text-gray-900">Jess</span>
            </div>
          </ListBox.Item>,
          <ListBox.LabelItem key="item-b" label="Item B" icon={CloudIcon} />,
        ]}
      />
    </FromElementTestSection>
  );
}

const COMBO_BOX_SCHEMA = yup.number().max(10);

function ComboBoxTest() {
  return (
    <FromElementTestSection type="ComboBox" schema={COMBO_BOX_SCHEMA}>
      <ComboBoxPlain
        field="test"
        placeholder="Starting typing to search..."
        options={names.map((name, index) => (
          <ComboBox.LabelItem key={index} label={name} icon={UserIcon} />
        ))}
      />
    </FromElementTestSection>
  );
}

export const debugRoute = {
  path: 'debug',
  element: <Debug />,
};

const names = [
  'Cayden Todd',
  'Ellena Davila',
  'Josephine Grant',
  'Jazmin Ray',
  'Maria Nolan',
  'Angela Rosales',
  'Noah Schmidt',
  'Judith Simmons',
  'Harry Hatfield',
  'Addie Bishop',
  'Isha Proctor',
  'Ruben Beasley',
  'Aneesa Silva',
  'Ned Underwood',
  "Theresa O'Connor",
  'Jane Mendoza',
  'Antony Clark',
  'Kasey Diaz',
  'Raymond Mack',
  'Clara Avila',
  'Elif Allen',
  'Madiha Gill',
  'Rhodri Mendez',
  'Jonathan Graham',
  'Ashwin Huffman',
  'Betty Carrillo',
  'Leyton Bartlett',
  'Teresa Saunders',
  'Leyla Perez',
  'Chanelle Schaefer',
  'Owain Norris',
  'Roy Dickson',
  'Brogan Marsh',
  'Aliza Mitchell',
  'Mikey Bowers',
  'Amir Garza',
  'Steffan Sykes',
  'Shreya Meadows',
  'Alan Maldonado',
  'Hollie Cameron',
  'Rehan Harrell',
  'Muhammad Hawkins',
  'Bill Weeks',
  'Xanthe Hendrix',
  'Constance Hogan',
  'Felicity Miles',
  'Cai Hayden',
  'Abdul Booth',
  'Gordon Fischer',
  'Erik Hodge',
];
