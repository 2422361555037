import { FormProvider, useForm } from 'react-hook-form';
import { SidePanelContent, SidePanelTitle } from '../../../lib/components/side-panel-outlet';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { useMountedState } from 'react-use';
import { isPlainObject, yup } from '../../../lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../lib/components/button';
import { RadioGroup } from '../../../lib/components/form/radio-group';
import { TextField } from '../../../lib/components/form/text-field';
import { TextareaField } from '../../../lib/components/form/textarea-field';
import { Calendar } from '../../../lib/components/form/calendar';
import { ProcedureComboBox } from '../../../lib/components/procedure-combo-box';
import { PractitionerRadioGroup } from '../../../lib/components/practitioner-radio-group';
import {
  EnumRadioGroup,
  defaultMakeEnumRadioGroupOptions,
} from '../../../lib/components/enum-radio-group';
import { NHI_REGEX } from '../../../lib/constants';
import { useSaveAppointment } from '../../../lib/api/actions/save-appointment';
import { useSearchPatient } from '../../../lib/api/actions/search-patient';
import {
  AvailableSlotGroups,
  CalendarDayAvailability,
  FormSection,
  ProcedureFormActions,
  SelectedSlotDetails,
} from './shared';
import { useGenericErrorsToast } from '../../../lib/components/toast';

export function NewAppointment() {
  const [patientDetails, setPatientDetails] = useState(null);
  return patientDetails ? (
    <NewAppointmentForm patientDetails={patientDetails} setPatientDetails={setPatientDetails} />
  ) : (
    <NhiSearchForm setPatientDetails={setPatientDetails} />
  );
}

NewAppointment.propTypes = {};

function NewAppointmentLayout({ submitHandler, children }) {
  return (
    <SidePanelContent
      header={<SidePanelTitle title="Book a new appointment" variant="fill" />}
      footer={<ProcedureFormActions label="Book" submitHandler={submitHandler} />}
    >
      <div
        className="grid xl:grid-cols-2 grow overflow-y-auto min-h-full"
        data-testid="new-appointment-form"
      >
        {children}
      </div>
    </SidePanelContent>
  );
}

NewAppointmentLayout.propTypes = {
  submitHandler: yup.mixed().callback(),
  children: yup.mixed().react().pt(),
};

const NHI_SEARCH_FORM_SCHEMA = yup.object().shape({
  nhiNumber: yup.string().matches(NHI_REGEX, 'A valid NHI is required'),
});

const NHI_SEARCH_FORM_DEFAULTS = {
  nhiNumber: '',
};

function NhiSearchForm({ setPatientDetails }) {
  const nhiSearchForm = useForm({
    resolver: yupResolver(NHI_SEARCH_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: NHI_SEARCH_FORM_DEFAULTS,
  });
  const { isSubmitting, isLoading, isValid } = nhiSearchForm.formState;
  const isMounted = useMountedState();

  // NHI search
  const searchPatient = useSearchPatient();
  const searchPatientHandler = async (values) => {
    const nhiNumber = values.nhiNumber;
    if (nhiNumber.length > 0) {
      try {
        const result = await searchPatient.execute({ nhiNumber });
        if (isMounted()) {
          setPatientDetails({
            nhiNumber,
            patientId: result.id ?? '',
            nameGiven: result.nameGiven ?? '',
            nameFamily: result.nameFamily ?? '',
            phoneNumber: result.phoneNumber ?? '',
            email: result.email ?? '',
          });
        }
      } catch {
        if (isMounted()) {
          setPatientDetails({
            nhiNumber,
            patientId: null,
            nameGiven: '',
            nameFamily: '',
            phoneNumber: '',
            email: '',
          });
        }
      }
    }
  };

  return (
    <FormProvider {...nhiSearchForm}>
      <NewAppointmentLayout>
        <div>
          <FormSection title="NHI number">
            <form
              className="m-0 flex justify-stretch space-x-4"
              onSubmit={nhiSearchForm.handleSubmit(searchPatientHandler)}
            >
              <div className="flex-grow">
                <TextField
                  field="nhiNumber"
                  placeholder="e.g. ABC1234"
                  disabled={isSubmitting || isLoading}
                  autoFocus
                />
              </div>
              <Button
                type="submit"
                label="Search"
                variant="outline"
                color="gray"
                disabled={isSubmitting || isLoading || !isValid}
                testId="nhi-search"
              />
            </form>
          </FormSection>
        </div>
      </NewAppointmentLayout>
    </FormProvider>
  );
}

NhiSearchForm.propTypes = {
  setPatientDetails: yup.mixed().callback().required(),
};

const NEW_PROCEDURE_FORM_SCHEMA = yup.object().shape({
  nhiNumber: yup.string().matches(NHI_REGEX, 'A valid NHI is required'),
  patientId: yup.string().nullable(),
  nameGiven: yup.string().max(64, 'Given name is too long').required('Given name is required'),
  nameFamily: yup.string().max(64, 'Family name is too long').required('Family name is required'),
  phoneNumber: yup.string().max(16, 'Phone number is too long'),
  email: yup.string().email('A valid email is required'),
  referralSource: yup.string().required('Referral source is required'),
  procedureCodeId: yup.string().required('Procedure selection is required'),
  appointmentNote: yup.string().max(100, 'Appointment note is too long'),
  practitionerId: yup.string().required('Practitioner is required'),
  listType: yup.string().required('List type is required'),
  selectedDate: yup.number(),
  calendarSlotDetails: yup
    .object({ calendarSlotId: yup.string().guid(), listId: yup.string().guid() })
    .required('Slot selection is required'),
});

const NEW_PROCEDURE_FORM_DEFAULTS = {
  nhiNumber: '',
  patientId: null,
  nameGiven: '',
  nameFamily: '',
  phoneNumber: '',
  email: '',
  referralSource: '',
  procedureCodeId: '',
  appointmentNote: '',
  practitionerId: 'any',
  listType: 'any',
  selectedDate: null,
  calendarSlotDetails: null,
};

function NewAppointmentForm({ patientDetails, setPatientDetails }) {
  const newAppointmentForm = useForm({
    resolver: yupResolver(NEW_PROCEDURE_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: { ...NEW_PROCEDURE_FORM_DEFAULTS, ...patientDetails },
  });
  const [referralSource, selectedProcedure, selectedDate] = newAppointmentForm.watch([
    'referralSource',
    'procedureCodeId',
    'selectedDate',
  ]);
  const navigate = useNavigate();
  const { date } = useParams();
  const genericErrorsToast = useGenericErrorsToast();

  // Save appointment
  const saveAppointment = useSaveAppointment();
  const saveAppointmentHandler = async (values) => {
    invariant(isPlainObject(values.calendarSlotDetails));
    const { listId, calendarSlotId } = values.calendarSlotDetails;
    try {
      await saveAppointment.execute({
        calendarSlotId,
        patientId: values.patientId,
        nhiNumber: values.nhiNumber,
        nameGiven: values.nameGiven,
        nameFamily: values.nameFamily,
        email: values.email,
        phoneNumber: values.phoneNumber,
        referralSource: values.referralSource,
        procedureCodeId: values.procedureCodeId,
        appointmentNote: values.appointmentNote,
        appointmentStatus: 'Booked',
      });
      navigate(`/dashboard/${date}/details/${listId}`, {
        replace: true,
        state: { highlight: calendarSlotId },
      });
    } catch (error) {
      genericErrorsToast('Failed to create appointment', error);
    }
  };

  // Form section visibility steps
  const showStep2 = selectedProcedure && referralSource;
  const showStep3 = showStep2 && selectedDate;

  return (
    <FormProvider {...newAppointmentForm}>
      <NewAppointmentLayout submitHandler={saveAppointmentHandler}>
        <div>
          <FormSection title="NHI number">
            <div className="flex justify-stretch space-x-4">
              <div className="flex-grow">
                <TextField
                  field="nhiNumber"
                  disabled
                  help={
                    patientDetails.patientId
                      ? `Found existing patient with NHI ${patientDetails.nhiNumber.toUpperCase()}.`
                      : `Patient with NHI ${patientDetails.nhiNumber.toUpperCase()} will be created.`
                  }
                />
              </div>
              <Button
                label="Change"
                variant="outline"
                color="gray"
                onClick={() => setPatientDetails(undefined)}
                testId="nhi-change"
              />
            </div>
          </FormSection>
          <FormSection title="Patient name">
            <div>
              <input type="hidden" {...newAppointmentForm.register('nhiNumber')} />
              <input type="hidden" {...newAppointmentForm.register('patientId')} />
              <TextField field="nameFamily" placeholder="Family name" autoFocus />
            </div>
            <div></div>
            <div>
              <TextField field="nameGiven" placeholder="Given name" />
            </div>
          </FormSection>
          <FormSection title="Patient phone">
            <div>
              <TextField field="phoneNumber" placeholder="Phone number" />
            </div>
          </FormSection>
          <FormSection title="Patient email">
            <div>
              <TextField field="email" placeholder="Email" />
            </div>
          </FormSection>
          <FormSection title="Referral source">
            <EnumRadioGroup name="ReferralSource" field="referralSource" />
          </FormSection>
          <FormSection title="Procedure">
            <ProcedureComboBox field="procedureCodeId" />
          </FormSection>
          {showStep2 ? (
            <>
              <FormSection title="Practitioner">
                <PractitionerRadioGroup field="practitionerId" />
              </FormSection>
              <FormSection title="List">
                <EnumRadioGroup
                  name="ListType"
                  field="listType"
                  makeEnumRadioGroupOptions={(enumValues) => [
                    <RadioGroup.LabelItem key="any" label="Any" />,
                    ...defaultMakeEnumRadioGroupOptions(enumValues),
                  ]}
                />
              </FormSection>
              <FormSection title="Appointment note">
                <TextareaField field="appointmentNote" />
              </FormSection>
            </>
          ) : null}
        </div>
        <div>
          {showStep2 ? (
            <FormSection
              title="Calendar"
              subtitle={
                <label className="block text-gray-400 text-sm">Select a calendar day</label>
              }
            >
              <Calendar disablePastMonths={true} DayComponent={CalendarDayAvailability} />
            </FormSection>
          ) : null}
          {showStep3 ? (
            <FormSection title="Select an available time" subtitle={<SelectedSlotDetails />}>
              <AvailableSlotGroups />
            </FormSection>
          ) : null}
        </div>
      </NewAppointmentLayout>
    </FormProvider>
  );
}

NewAppointmentForm.propTypes = {
  patientDetails: yup
    .object({
      nhiNumber: yup.string().required(),
      patientId: yup.string().guid().optional(),
    })
    .required(),
  setPatientDetails: yup.mixed().callback().required(),
};
