import pino from 'pino';

const DISPLAY_TIME = false;

const logger = pino({
  level: 'debug',
  browser: {
    asObject: true,
    write: {
      trace: function (o) {
        log(console.debug, o);
      },
      debug: function (o) {
        log(console.debug, o);
      },
      info: function (o) {
        log(console.info, o);
      },
      warn: function (o) {
        log(console.warn, o);
      },
      error: function (o) {
        log(console.error, o);
      },
    },
  },
});

function log(func, { time, level, msg, ...rest }) {
  try {
    level;
    const args = [];
    if (DISPLAY_TIME) {
      const t = new Date(time);
      const hh = t.getHours().toString().padStart(2, '0');
      const mm = t.getMinutes().toString().padStart(2, '0');
      const ss = t.getSeconds().toString().padStart(2, '0');
      const sss = t.getMilliseconds().toString().padStart(3, '0');
      args.push(`[${hh}:${mm}:${ss}.${sss}] ${msg}`);
    } else {
      args.push(msg);
    }
    if (Object.keys(rest).length > 0) {
      args.push(rest);
    }
    func.apply(console, args);
  } catch {
    // Ignore log failures
  }
}

export { logger };
