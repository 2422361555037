import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { yup } from '../../utils';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

export function ErrorMessage({ error, help = '' }) {
  return (
    <p
      className={clsx(
        'text-xs',
        error ? 'text-error-500 mt-2' : help ? 'text-gray-500 mt-2' : 'hidden',
      )}
    >
      {error ? error : help}
    </p>
  );
}

ErrorMessage.propTypes = {
  error: yup.string().pt(),
  help: yup.string().pt(),
};

export function ErrorIcon({ error, className }) {
  return error ? (
    <div
      className={clsx('absolute inset-y-0 right-0 flex items-center pr-1.5', className)}
      data-testid="error-icon"
    >
      <Tooltip content={error} mode="error">
        <Tooltip.Trigger>
          <ExclamationCircleIcon className="h-5 w-5 text-error-500" aria-hidden="true" />
        </Tooltip.Trigger>
        <Tooltip.Content colour="red">{error}</Tooltip.Content>
      </Tooltip>
    </div>
  ) : null;
}

ErrorIcon.propTypes = {
  error: yup.string().pt(),
  className: yup.string().pt(),
};
