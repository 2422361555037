import { FilledNotes } from './filled-notes';
import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import { useReferralSourceColour } from '../../hooks/use-referral-source-colour';
import { useState } from 'react';
import { Td, TdArrivalTime, TdSlotNumber, APPOINTMENT_STATUSES } from './shared';
import { FilledSlotRowEdit } from './filled-slot-row-edit';
import { FilledSlotRowMove } from './filled-slot-row-move';
import {
  FilledSlotRowCancel,
  FilledSlotRowEnteredInError,
} from './filled-slot-row-remove-appointment';
import { FilledSlotRowConfirmDeletion } from './filled-slot-row-confirm-deletion';
import { AppointmentStatusLabel } from './appointment-status-label';
import { UpdateWebPasSyncCheckbox } from './update-web-pass-sync-checkbox';
import { EMPTY_OBJECT, IDENTITY } from '../../constants';
import { Label } from '../label';
import { Button } from '../button';
import clsx from 'clsx';
import { useIsHistoric } from '../../hooks/use-is-historic';

export function FilledSlotRow({ slot, index }) {
  const [mode, setMode] = useState(0);
  const [initialAppointmentStatus, setInitialAppointmentStatus] = useState(null);
  switch (mode) {
    case 1:
      return (
        <FilledSlotRowEdit
          index={index}
          slot={slot}
          setMode={setMode}
          initialAppointmentStatus={initialAppointmentStatus}
        />
      );
    case 2:
      return (
        <FilledSlotRowEnteredInError
          index={index}
          slot={slot}
          setMode={setMode}
          setInitialAppointmentStatus={setInitialAppointmentStatus}
        />
      );
    case 4:
      return (
        <FilledSlotRowCancel
          index={index}
          slot={slot}
          setMode={setMode}
          setInitialAppointmentStatus={setInitialAppointmentStatus}
        />
      );
    case 3:
      return <FilledSlotRowMove index={index} slot={slot} setMode={setMode} />;
    default:
      switch (slot.appointment.status) {
        case APPOINTMENT_STATUSES.ENTERED_IN_ERROR:
          return (
            <FilledSlotRowConfirmDeletion
              index={index}
              slot={slot}
              text="Appointment deleted in Soopa. Please check box to confirm deleted in WebPAS, and unlock slot."
            />
          );
        case APPOINTMENT_STATUSES.CANCELLED:
          return (
            <FilledSlotRowConfirmDeletion
              index={index}
              slot={slot}
              text="Appointment cancelled in Soopa. Please check box to confirm cancelled in WebPAS, and unlock slot."
            />
          );
        default:
          return (
            <FilledSlotRowDefault
              index={index}
              slot={slot}
              setMode={setMode}
              setInitialAppointmentStatus={setInitialAppointmentStatus}
            />
          );
      }
  }
}

FilledSlotRow.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
};

function FilledSlotRowDefault({ slot, index, setMode, setInitialAppointmentStatus }) {
  const isHistory = useIsHistoric(slot.arrivalTimeTimestamp);

  const cellProps = !isHistory
    ? {
        onClickHead: () => setMode(3),
        onClick: () => {
          setInitialAppointmentStatus(null);
          setMode(1);
        },
      }
    : EMPTY_OBJECT;

  let bg = null;
  switch (slot.appointment.status.toLowerCase()) {
    case 'pending':
      bg = 'bg-orange-50';
      break;
    case 'proposed':
      bg = 'bg-pink-50';
      break;
  }

  return (
    <tr className={clsx('group', bg)} data-testid={`slot-${index}`} data-slot-type="filled-default">
      <TdSlotNumber index={index} {...cellProps} />
      <TdArrivalTime slot={slot} {...cellProps} />
      <Td className="" {...cellProps}>
        <AppointmentStatusLabel status={slot.appointment.status} />
      </Td>
      <Td className="" {...cellProps}>
        {slot.appointment.procedure.procedureCode.longDisplay}
      </Td>
      <Td className="" {...cellProps}>
        <FilledNotes notes={slot.appointment.notes} />
      </Td>
      <Td className="uppercase" {...cellProps}>
        {slot.appointment.patient?.nhiNumber}
      </Td>
      <Td className="" {...cellProps}>
        <span className="uppercase line-clamp-2">{slot.appointment.patient?.nameFamily}</span>
      </Td>
      <Td className="" {...cellProps}>
        <span className="line-clamp-2">{slot.appointment.patient?.nameGiven}</span>
      </Td>
      <Td className="truncate" {...cellProps}>
        {slot.appointment.patient?.phoneNumber}
      </Td>
      <Td className="truncate" {...cellProps}>
        {slot.appointment.patient?.email}
      </Td>
      <Td className="" {...cellProps}>
        <ReferralSourceLabel source={slot.appointment.referralSource} />
      </Td>
      <Td
        className="text-center"
        onClickHead={cellProps.onClickHead}
        onClick={!isHistory ? IDENTITY : null}
      >
        {slot.appointment.patient?.nhiNumber ? <UpdateWebPasSyncCheckbox slot={slot} /> : null}
      </Td>
      <Td
        className="text-center transition opacity-0 group-has-[.section2:hover]:opacity-100"
        {...cellProps}
      >
        <div className="flex justify-center items-center">
          <Button variant="plain" label="EDIT" className={isHistory ? 'invisible' : null} />
        </div>
      </Td>
    </tr>
  );
}

FilledSlotRowDefault.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
  setInitialAppointmentStatus: yup.mixed().callback().pt(),
};

function ReferralSourceLabel({ source }) {
  const style = useReferralSourceColour(source);
  return <Label label={source} className={style} />;
}

ReferralSourceLabel.propTypes = {
  source: yup.string().required().pt(),
};
