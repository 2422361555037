import { yup } from '../utils';
import {
  useContext,
  useState,
  useMemo,
  useRef,
  createContext,
  forwardRef,
  cloneElement,
} from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  useTransitionStatus,
  arrow,
  FloatingArrow,
} from '@floating-ui/react';
import clsx from 'clsx';

function useTooltip({ initialOpen = false, placement = 'bottom', offsetPixels = 15 } = {}) {
  const [open, setOpen] = useState(initialOpen);
  const arrowRef = useRef(null);
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetPixels),
      flip({ padding: 20 }),
      shift({ padding: 10 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const transition = useTransitionStatus(context);
  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      arrowRef,
      ...data,
      ...interactions,
      ...transition,
    }),
    [transition, interactions, data],
  );
}

const TooltipContext = createContext(null);

const useTooltipContext = () => {
  return useContext(TooltipContext);
};

export function Tooltip({ children, initialOpen, placement, offsetPixels }) {
  const tooltip = useTooltip({ initialOpen, placement, offsetPixels });
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

const TooltipTrigger = forwardRef(function TooltipTrigger({ children, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
    }),
  );
});

const TooltipContent = forwardRef(function TooltipContent(
  { colour = 'indigo', children, ...props },
  propRef,
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  return context.isMounted ? (
    <FloatingPortal>
      <div
        ref={ref}
        className={clsx(
          'relative z-20 text-center rounded transition-opacity opacity-0 pointer-events-none transition-opacity data-[status=open]:duration-200 data-[status=close]:duration-200 data-[status=open]:opacity-100',
          {
            'bg-red-600': colour === 'red',
            'bg-indigo-600': colour === 'indigo',
          },
        )}
        style={context.floatingStyles}
        data-status={context.status}
        {...context.getFloatingProps(props)}
      >
        <div className="inline-block text-left text-white text-sm py-1 px-3 break-word">
          {children}
        </div>
        <FloatingArrow
          ref={context.arrowRef}
          context={context}
          className={clsx({
            'fill-red-600': colour === 'red',
            'fill-indigo-600': colour === 'indigo',
          })}
        />
      </div>
    </FloatingPortal>
  ) : null;
});

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;

Tooltip.propTypes = {
  children: yup.mixed().react().pt(),
  initialOpen: yup.boolean().pt(),
  placement: yup.mixed().oneOf(['bottom', 'top']).pt(),
  offsetPixels: yup.number().min(0).pt(),
};

TooltipTrigger.propTypes = {
  children: yup.mixed().react().pt(),
};

TooltipContent.propTypes = {
  children: yup.mixed().react().pt(),
  colour: yup.mixed().oneOf(['indigo', 'red']).pt(),
};
