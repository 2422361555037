import { useMemo } from 'react';
import { Tab } from '@headlessui/react';
import { yup } from '../utils';
import clsx from 'clsx';
import { Link } from 'react-router';

function Panel({ children }) {
  return children;
}

Panel.propTypes = {
  label: yup.string().required().pt(),
  children: yup.mixed().react().required().pt(),
};

export function Tabs({ selectedIndex, onChange, children }) {
  const { tabs, panels } = useMemo(
    () =>
      children
        .filter((x) => x.type === Panel)
        .reduce(
          (accumulator, tab, index) => {
            accumulator.tabs.push(
              <Tab
                key={`tab${index}`}
                className="transition py-2.5 px-4 text-sm font-normal leading-5 focus:outline-none border-box border-b-2 text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300 ui-active:bg-red-600 ui-selected:text-indigo-600 ui-selected:border-indigo-600 ui-selected:font-medium ui-selected:hover:bg-transparent"
                as={Link}
                to={tab.props.to}
              >
                {tab.props.label ?? ''}
              </Tab>,
            );
            accumulator.panels.push(
              <Tab.Panel
                key={`panel${index}`}
                className={clsx(
                  'motion-safe:animate-fade-in rounded-xl bg-white pt-5 focus:outline-none',
                )}
              >
                {tab}
              </Tab.Panel>,
            );
            return accumulator;
          },
          { tabs: [], panels: [] },
        ),
    [children],
  );

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChange}>
      <Tab.List className="flex space-x-1">{tabs}</Tab.List>
      <Tab.Panels className="mt-[-1px] border-t border-gray-200">{panels}</Tab.Panels>
    </Tab.Group>
  );
}

Tabs.propTypes = {
  selectedIndex: yup.number().pt(),
  onChange: yup.mixed().callback().pt(),
  children: yup.mixed().react().required().pt(),
};

Tabs.Panel = Panel;
