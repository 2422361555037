import { Fragment, createElement, useMemo } from 'react';
import { Listbox as HeadlessListbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { yup } from '../../utils';
import { useController } from 'react-hook-form';
import { ErrorIcon } from './error-message';
import clsx from 'clsx';
import { flip, offset, useFloating } from '@floating-ui/react-dom';
import { FieldDecoration } from './field-decoration';

function DefaultSelectedComponent({ value, options, placeholder }) {
  return useMemo(() => {
    const hasValue = !(
      value === undefined ||
      value === null ||
      ((Array.isArray(value) || typeof value === 'string') && value.length === 0)
    );
    if (hasValue) {
      const findOption = (key) => {
        return options.find((option) => option.key === key) ?? null;
      };
      if (Array.isArray(value)) {
        if (value.length === 1) {
          return findOption(value[0]);
        } else {
          return 'Multiple';
        }
      } else {
        return findOption(value);
      }
    } else {
      return <span className="opacity-50">{placeholder}</span>;
    }
  }, [options, placeholder, value]);
}

export function ListBoxPlain({ field, ...props }) {
  if (field) {
    return <ControlledListBox {...props} field={field} testId={field} />;
  } else {
    return <UncontrolledListBox {...props} testId={field} />;
  }
}

ListBoxPlain.propTypes = {
  field: yup.string().required().pt(),
};

export function ListBox(props) {
  return (
    <FieldDecoration {...props}>
      <ListBoxPlain {...props} />
    </FieldDecoration>
  );
}

function ControlledListBox({ field, ...props }) {
  const { field: inputProps, fieldState } = useController({ name: field });
  const { ref, ...forwardInputProps } = inputProps;
  // The ref returned from useController can not be passed to function component
  // UncontrolledListBox (react will emit a warning).
  ref;
  return (
    <UncontrolledListBox {...props} {...forwardInputProps} error={fieldState.error?.message} />
  );
}

ControlledListBox.propTypes = {
  field: yup.string().required().pt(),
  ref: yup.object().pt(),
};

function UncontrolledListBox({
  placeholder = '',
  error = '',
  disabled = false,
  decorated = false,
  options = [],
  multiple = false,
  SelectedComponent = DefaultSelectedComponent,
  testId,
  onBlur,
  ...props
}) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(4), flip()],
  });
  const hasError = error.length > 0;
  return (
    <HeadlessListbox
      {...props}
      onBlur={(e) => {
        // Don't trigger blur if we are refocusing to a different child element
        // of the listbox.
        if (typeof onBlur === 'function' && !refs.reference.current?.contains(e.relatedTarget)) {
          onBlur(e);
        }
      }}
      name={undefined}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => {
        const value = props.value; // eslint-disable-line react/prop-types
        return (
          <div
            ref={refs.setReference}
            className={clsx(
              'relative min-w-[54px] listbox-holder',
              disabled && 'opacity-50 cursor-not-allowed',
            )}
            data-testid={testId}
          >
            <HeadlessListbox.Button
              className={clsx(
                'relative w-full flex flex-row cursor-default rounded-md bg-white py-1.5 px-2.5 text-left shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 focus:ring-2 focus:outline-none overflow-hidden',
                hasError
                  ? 'text-error-500 ring-error-500 focus:outline-none focus:ring-error-5000 disabled:ring-error-500 pr-[44px]'
                  : 'text-gray-900 focus:ring-indigo-500 ring-gray-300 pr-[22px]',
              )}
            >
              <div className="truncate">
                <SelectedComponent value={value} options={options} placeholder={placeholder} />
              </div>
              <span>&nbsp;</span>
              {error && !decorated && <ErrorIcon error={error} className="mr-6" />}
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </HeadlessListbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessListbox.Options
                ref={refs.setFloating}
                style={floatingStyles}
                className="z-20 max-h-56 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListBoxOptionsMemo options={options} />
              </HeadlessListbox.Options>
            </Transition>
          </div>
        );
      }}
    </HeadlessListbox>
  );
}

UncontrolledListBox.propTypes = {
  field: yup.string().required().pt(),
  placeholder: yup.string().pt(),
  disabled: yup.boolean().pt(),
  decorated: yup.boolean().pt(),
  options: yup.mixed().react().pt(),
  error: yup.string().pt(),
  multiple: yup.boolean().pt(),
  SelectedComponent: yup.mixed().react().pt(),
  testId: yup.string().pt(),
  onBlur: yup.mixed().callback().pt(),
};

function ListBoxOptionsMemo({ options }) {
  return useMemo(
    () =>
      options.map((option) =>
        option.type === ListBox.UnselectableItem ? (
          option
        ) : (
          <HeadlessListbox.Option
            key={option.key}
            className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 ui-active:bg-indigo-50"
            value={option.key}
            disabled={option.disabled}
          >
            {option}
          </HeadlessListbox.Option>
        ),
      ),
    [options],
  );
}

function ListBoxItem({ children }) {
  return (
    <>
      <div className="flex items-center truncate">{children}</div>
      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-indigo-600 invisible ui-selected:visible">
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
    </>
  );
}

ListBoxItem.propTypes = {
  children: yup.mixed().react().pt(),
};

function ListBoxLabelItem({ label, icon }) {
  return (
    <ListBoxItem>
      <div className="flex items-center truncate">
        {icon
          ? createElement(icon, {
              className: 'h-5 w-5 flex-shrink-0 mr-2',
              'aria-hidden': true,
            })
          : null}
        <span className="ui-selected:font-semibold font-normal block truncate">{label}</span>
      </div>
    </ListBoxItem>
  );
}

ListBoxLabelItem.propTypes = {
  label: yup.string().required().pt(),
  icon: yup.mixed().react().pt(),
};

function UnselectableListBoxItem({ children }) {
  return children;
}

UnselectableListBoxItem.propTypes = {
  children: yup.mixed().react().pt(),
};

ListBox.Item = ListBoxItem;
ListBox.LabelItem = ListBoxLabelItem;
ListBox.UnselectableItem = UnselectableListBoxItem;

export function LoadingListBox(props) {
  // eslint-disable-next-line react/prop-types
  const { field, ...forwardProps } = props;
  return (
    <UncontrolledListBox {...forwardProps} options={[]} disabled={true} placeholder="Loading" />
  );
}
