import { useMemo } from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { yup } from '../utils';
import clsx from 'clsx';

export function Avatar({ url, alt, className }) {
  return (
    <div className={clsx('rounded-full bg-gray-200', className)} data-avatar={alt}>
      {url?.length > 0 ? (
        <img src={url} alt={alt} className="w-full h-full before:border-0" />
      ) : (
        <UserIcon className="w-full h-full rounded-full border p-[2px] border-gray-300" />
      )}
    </div>
  );
}

Avatar.propTypes = {
  url: yup.string().pt(),
  alt: yup.string().required().pt(),
  className: yup.string().pt(),
};

export function PractitionerAvatar({ practitioner, className }) {
  const initials = useMemo(() => {
    const first = practitioner.nameGiven?.[0]?.toUpperCase() ?? '';
    const second = practitioner.nameFamily?.[0]?.toUpperCase() ?? '';
    return first + second;
  }, [practitioner.nameFamily, practitioner.nameGiven]);
  return <Avatar url={practitioner.avatarUrl} alt={initials} className={className} />;
}

PractitionerAvatar.propTypes = {
  practitioner: yup
    .object({
      avatarUrl: yup.string(),
      nameText: yup.string(),
    })
    .pt(),
  className: yup.string().pt(),
};
