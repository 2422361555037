import { FormProvider, useForm } from 'react-hook-form';
import { LIST_SCHEMA } from '../api/schemas';
import { yup } from '../utils';
import { PractitionerAvatar } from './avatar';
import { Table, Tbody, Td, Th, Thead, Tr } from './table';
import { useTranslation } from 'react-i18next';
import { useProcedureCategory, useProcedureCategoryGroup } from '../api/store/procedures';
import { Button } from './button';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useLatest, useMountedState } from 'react-use';
import { makePractitionerListBoxOptions } from './practitioner-list-box';
import { NumberFieldPlain } from './form/number-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { ListBoxPlain } from './form/list-box';
import { usePractitioners } from '../api/store/practitioners';
import clsx from 'clsx';
import { EnumListBox } from './enum-list-box';
import { useUpdateList } from '../api/actions/update-list';

export function ListPreferences({ list }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[1fr_2fr] gap-4">
      <div>
        <Table className="grid-cols-[2fr_3fr_9rem]" data-testid="list-settings">
          <Tbody>
            <TrEditable
              label="Specialist"
              list={list}
              Component={Practitioner}
              EditComponent={PractitionerEdit}
              className="border-t-0"
              testId="practitioner"
            />
            <Tr data-row-type="available-slots">
              <Td>Available slots</Td>
              <Td>{`${list.availableSlots ?? 0}`}</Td>
              <Td></Td>
            </Tr>
            <TrEditable
              label="Min points (target)"
              list={list}
              Component={TargetProcedurePoints}
              EditComponent={TargetProcedurePointsEdit}
              testId="target-points"
            />
            <TrEditable
              label="Max points"
              list={list}
              Component={MaximumProcedurePoints}
              EditComponent={MaximumProcedurePointsEdit}
              testId="max-points"
            />
            <TrEditable
              label="List type"
              list={list}
              Component={ListType}
              EditComponent={ListTypeEdit}
              testId="list-type"
            />
          </Tbody>
        </Table>
      </div>
      <div>
        <ListRulesetTable list={list} />
        <div className="pt-4">
          <Button
            label="Add new rule"
            icon={PlusCircleIcon}
            variant="outline"
            color="gray"
            disabled
          />
        </div>
      </div>
    </div>
  );
}

function ListRulesetTable({ list }) {
  const ruleRows = useMemo(
    () => [
      ...(list.listRuleset?.procedureCategoryRules?.map((rule) => (
        <ProcedureCategoryRule key={rule.id} rule={rule} />
      )) ?? []),
      ...(list.listRuleset?.procedureCategoryGroupRules?.map((rule) => (
        <ProcedureCategoryGroupRule key={rule.id} rule={rule} />
      )) ?? []),
      ...(list.listRuleset?.referralSourceRules?.map((rule) => (
        <ReferralSourceRule key={rule.id} rule={rule} />
      )) ?? []),
    ],
    [
      list.listRuleset?.procedureCategoryGroupRules,
      list.listRuleset?.procedureCategoryRules,
      list.listRuleset?.referralSourceRules,
    ],
  );
  return (
    <Table className="grid-cols-[2fr_2fr_1fr_3fr]" data-testid="list-ruleset">
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th>Rule</Th>
          <Th>Number</Th>
          <Th>Is</Th>
        </Tr>
      </Thead>
      <Tbody>{ruleRows}</Tbody>
    </Table>
  );
}

ListPreferences.propTypes = ListRulesetTable.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};

function TrEditable({ label, list, Component, EditComponent, className, testId }) {
  const isMounted = useMountedState();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [onSave, setOnSave] = useState();

  const { loading, execute } = useAsyncCallback(async () => {
    try {
      setSaving(true);
      await onSave();
      if (isMounted()) {
        setEditing(false);
      }
    } catch {
      // Should we handler errors here?
    } finally {
      setSaving(false);
    }
  });

  const cancelHandler = useCallback(() => {
    setEditing(false);
  }, []);

  const maybeSetEditing = editing ? undefined : () => setEditing(true);

  return (
    <Tr data-row-type={editing ? `${testId}-edit` : testId}>
      <Td onClick={maybeSetEditing} className={className}>
        {label}
      </Td>
      <Td onClick={maybeSetEditing} className={className}>
        {createElement(editing ? EditComponent : Component, { list, setOnSave })}
      </Td>
      <Td onClick={maybeSetEditing} className={clsx(className, 'pr-3 justify-end')}>
        {editing ? (
          <div className="flex gap-x-1 items-center justify-end">
            <Button
              variant="plain"
              label="SAVE"
              type="submit"
              onClick={execute}
              disabled={!onSave || saving}
            />
            <Button
              icon={XMarkIcon}
              variant="plain"
              labelClassName="sr-only"
              onClick={cancelHandler}
              disabled={loading || saving}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center transition opacity-0 group-hover:opacity-100">
            <Button variant="plain" label="EDIT" onClick={() => setEditing(true)} />
          </div>
        )}
      </Td>
    </Tr>
  );
}

TrEditable.propTypes = {
  label: yup.string().required().pt(),
  list: LIST_SCHEMA.required().pt(),
  Component: yup.mixed().react().required().pt(),
  EditComponent: yup.mixed().react().required().pt(),
  className: yup.string().pt(),
  testId: yup.string().pt(),
};

function useEditableRowForm(formOptions, setOnSave) {
  const form = useForm(formOptions);
  const updateList = useUpdateList();
  const { handleSubmit } = form;
  const { isDirty, isValid } = form.formState;
  const latestSetOnSave = useLatest(setOnSave);

  const submitHandler = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          await updateList.execute(values);
        } catch (e) {
          console.error('Update list failed', e);
        }
      }),
    [handleSubmit, updateList],
  );

  // Pass the "save" state to the parent component
  useEffect(() => {
    const setOnSave = latestSetOnSave.current;
    if (isDirty && isValid) {
      setOnSave((current) => (current !== submitHandler ? submitHandler : current));
    }
    return () => setOnSave((current) => (current !== undefined ? undefined : current));
  }, [isDirty, isValid, latestSetOnSave, setOnSave, submitHandler]);

  return form;
}

function Practitioner({ list }) {
  return (
    <div className="flex flex-row items-center space-x-2">
      <PractitionerAvatar practitioner={list.practitioner} className="size-8" />
      <span className="truncate max-w-full text-sm">{list.practitioner.nameText}</span>
    </div>
  );
}

function PractitionerEdit({ list, setOnSave }) {
  const form = useEditableRowForm(
    {
      defaultValues: { listId: list.id, practitionerId: list.practitioner.id },
      resolver: yupResolver(yup.object({ practitionerId: yup.string().guid().required() }), {
        mode: 'sync',
      }),
    },
    setOnSave,
  );
  const { isSubmitting } = form.formState;
  const practitioners = usePractitioners();
  const options = useMemo(() => makePractitionerListBoxOptions(practitioners), [practitioners]);
  return (
    <FormProvider {...form}>
      <ListBoxPlain
        field="practitionerId"
        autoFocus
        className="w-full"
        options={options}
        placeholder="Select practitioner"
        disabled={isSubmitting}
      />
    </FormProvider>
  );
}

function TargetProcedurePoints({ list }) {
  return `${list.targetProcedurePoints ?? 0}`;
}

function TargetProcedurePointsEdit({ list, setOnSave }) {
  const form = useEditableRowForm(
    {
      defaultValues: {
        listId: list.id,
        targetProcedurePoints: list.targetProcedurePoints ?? 0,
      },
      resolver: yupResolver(yup.object({ targetProcedurePoints: yup.number().min(1).required() }), {
        mode: 'sync',
      }),
    },
    setOnSave,
  );
  return (
    <FormProvider {...form}>
      <NumberFieldPlain field="targetProcedurePoints" autoFocus className="w-full" min={1} />
    </FormProvider>
  );
}

function MaximumProcedurePoints({ list }) {
  return `${list.maximumProcedurePoints ?? 0}`;
}

function MaximumProcedurePointsEdit({ list, setOnSave }) {
  const form = useEditableRowForm(
    {
      defaultValues: {
        listId: list.id,
        maximumProcedurePoints: list.maximumProcedurePoints ?? 0,
      },
      resolver: yupResolver(
        yup.object({ maximumProcedurePoints: yup.number().min(1).required() }),
        {
          mode: 'sync',
        },
      ),
    },
    setOnSave,
  );
  return (
    <FormProvider {...form}>
      <NumberFieldPlain field="maximumProcedurePoints" autoFocus className="w-full" min={1} />
    </FormProvider>
  );
}

function ListType({ list }) {
  return `${list.listType}`;
}

function ListTypeEdit({ list, setOnSave }) {
  const form = useEditableRowForm(
    {
      defaultValues: { listId: list.id, listType: list.listType },
      resolver: yupResolver(yup.object({ listType: yup.string().required() }), {
        mode: 'sync',
      }),
    },
    setOnSave,
  );
  return (
    <FormProvider {...form}>
      <div className="w-full">
        <EnumListBox name="ListType" field="listType" />
      </div>
    </FormProvider>
  );
}

Practitioner.propTypes =
  TargetProcedurePoints.propTypes =
  MaximumProcedurePoints.propTypes =
  ListType.propTypes =
    {
      list: LIST_SCHEMA.required().pt(),
    };

PractitionerEdit.propTypes =
  TargetProcedurePointsEdit.propTypes =
  MaximumProcedurePointsEdit.propTypes =
  ListTypeEdit.propTypes =
    {
      list: LIST_SCHEMA.required().pt(),
      setOnSave: yup.mixed().callback().required().pt(),
    };

const RULE_SHARED_PROPS = {
  id: yup.string().guid(),
  ruleType: yup.string(),
  value: yup.number(),
};

function RuleValue({ rule }) {
  const { t } = useTranslation();
  if (rule.ruleType !== 'OnlyAllow' && rule.ruleType !== 'DoNotAllow') {
    return rule.value;
  } else {
    return <span className="text-gray-300">{t('n/a')}</span>;
  }
}

RuleValue.propTypes = {
  rule: yup.object(RULE_SHARED_PROPS).required().pt(),
};

function RuleType({ rule }) {
  const { t } = useTranslation();
  return t(`enums.RuleType.${rule.ruleType}`);
}

RuleType.propTypes = {
  rule: yup.object(RULE_SHARED_PROPS).required().pt(),
};

function ReferralSourceRule({ rule }) {
  const { t } = useTranslation();
  return (
    <Tr>
      <Td className="font-semibold">Referral source</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{t(`enums.ReferralSource.${rule.referralSource}`)}</Td>
    </Tr>
  );
}

ReferralSourceRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      referralSource: yup.string(),
    })
    .required()
    .pt(),
};

function ProcedureCategoryRule({ rule }) {
  const procedureCategory = useProcedureCategory(rule.procedureCategoryId);
  return (
    <Tr>
      <Td>Procedure Category</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{procedureCategory?.shortDisplay ?? null}</Td>
    </Tr>
  );
}

ProcedureCategoryRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      procedureCategoryId: yup.string().guid(),
    })
    .required()
    .pt(),
};

function ProcedureCategoryGroupRule({ rule }) {
  const procedureCategoryGroup = useProcedureCategoryGroup(rule.procedureCategoryGroupId);
  return (
    <Tr>
      <Td>Procedure Category</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{procedureCategoryGroup?.shortDisplay ?? null}</Td>
    </Tr>
  );
}

ProcedureCategoryGroupRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      procedureCategoryGroupId: yup.string().guid(),
    })
    .required()
    .pt(),
};
