import { useMemo } from 'react';
import { SidePanelOutlet } from '../../../lib/components/side-panel-outlet';
import { Navigate } from 'react-router';
import { dayjs } from '../../../lib/utils';
import { useRelativeDateGeneratorFromUrl } from '../../../lib/hooks/use-relative-date-generator';
import { NewAppointment } from './new-appointment';
import { DayBlock } from './day-block';
import { ListDetails } from './list-details';
import { SearchAppointment } from './search-appointment';
import { MoveAppointment } from './move-appointment';

const URL_DATE_FORMAT = 'YYYYMMDD';
const DAYS_TO_RENDER = 14;

function Dashboard() {
  return (
    <div className="flex flex-wrap flex-col h-full">
      <Schedules />
      <SidePanelOutlet />
    </div>
  );
}

function Schedules() {
  const generator = useRelativeDateGeneratorFromUrl();

  const schedules = useMemo(
    () =>
      Array.apply(null, Array(DAYS_TO_RENDER)).reduce((accumulator, _, index) => {
        generator;
        const date = generator.at(index);
        accumulator.push(<DayBlock key={date.t.toISOString()} t={date.t} />);
        return accumulator;
      }, []),
    [generator],
  );

  return (
    <div className="relative flex flex-row flex-wrap overflow-hidden h-full max-h-full max-w-full gap-3">
      {schedules}
    </div>
  );
}

function NavigateToToday() {
  const today = dayjs().format(URL_DATE_FORMAT);
  return <Navigate to={today} replace />;
}

export const dashboardRoute = {
  path: 'dashboard',
  children: [
    {
      index: true,
      Component: NavigateToToday,
    },
    {
      path: ':date',
      element: <Dashboard />,
      children: [
        {
          path: 'new',
          element: <NewAppointment />,
          handle: {
            panelSize: 'large',
            closePath: '..',
          },
        },
        {
          path: 'search',
          element: <SearchAppointment />,
          handle: {
            panelSize: 'large',
            closePath: '..',
            closeWithoutParams: ['term'],
          },
        },
        {
          path: 'details/:listId',
          children: [
            {
              index: true,
              element: <ListDetails />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../..',
              },
            },
            {
              path: 'bookings',
              element: <ListDetails />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../../..',
              },
            },
            {
              path: 'preferences',
              element: <ListDetails />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../../..',
              },
            },
            {
              path: 'move/:appointmentId',
              element: <MoveAppointment />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../..',
              },
            },
          ],
        },
      ],
    },
  ],
};
