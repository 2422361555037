import { useFormContext } from 'react-hook-form';
import { yup } from '../../utils';
import { EMPTY_OBJECT } from '../../constants';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useCallback } from 'react';
import { FieldDecoration } from './field-decoration';
import clsx from 'clsx';
import { ErrorIcon } from './error-message';

export function NumberFieldPlain({
  field,
  placeholder = '',
  form,
  min = -Infinity,
  max = Infinity,
  disabled = false,
  decorated = false,
}) {
  const { register, getValues, setValue, formState } = useFormContext();
  const { errors } = formState;
  const error = errors[field]?.message;
  const inputProps = field ? register(field) : EMPTY_OBJECT;

  const adjustValue = useCallback(
    (offset) => {
      setValue(field, Math.min(max, Math.max(min, Number(getValues(field) ?? 0) + offset)), {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [field, getValues, max, min, setValue],
  );

  return (
    <div
      className={clsx(
        'box-border overflow-hidden relative w-full inline-flex flex-nowrap items-stretch justify-center rounded-md shadow-sm rounded-md border-0 shadow-sm',
        disabled && 'opacity-50',
      )}
      role="group"
    >
      <button
        className="peer block h-9 w-9 text-gray-900 bg-gray-200 text-center focus-visible:outline-none focus:bg-gray-300 disabled:cursor-not-allowed"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          adjustValue(-1);
        }}
      >
        <div className="w-full h-full flex justify-center items-center">
          <MinusIcon className="size-5" />
        </div>
      </button>
      <input
        {...inputProps}
        type="number"
        form={form}
        id={field}
        min={min}
        max={max}
        className={clsx(
          'peer block border-y-0 border-x-1 border-gray-300 w-full py-1.5 sm:text-sm sm:leading-6 outline-0 disabled:cursor-not-allowed focus:ring-0 active:ring-0 focus:border-gray-300 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
          error && 'text-error-500 placeholder:text-error-300',
        )}
        placeholder={placeholder}
        disabled={disabled}
        aria-invalid={!!error}
      />
      <button
        className="peer block h-9 w-9 text-gray-900 bg-gray-200 text-center disabled:cursor-not-allowed focus-visible:outline-none focus:bg-gray-300"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          adjustValue(1);
        }}
      >
        <div className="w-full h-full flex justify-center items-center">
          <PlusIcon className="size-5" />
        </div>
      </button>
      <ErrorIcon error={!decorated ? error : undefined} className="mr-9" />
      <div
        className={clsx(
          'absolute top-0 bottom-0 left-0 right-0 pointer-events-none border rounded-md ring-none peer-[:focus]:ring-2 peer-[:focus]:ring-inset peer-[:focus]:border-none',
          error
            ? 'border-error-500 ring-error-500 focus:ring-error-500'
            : 'border-gray-300 peer-[:focus]:ring-indigo-500',
        )}
      ></div>
    </div>
  );
}

NumberFieldPlain.propTypes = {
  field: yup.string().required().pt(),
  placeholder: yup.string().pt(),
  form: yup.string().pt(),
  min: yup.number().pt(),
  max: yup.number().pt(),
  disabled: yup.boolean().pt(),
  decorated: yup.boolean().pt(),
};

export function NumberField(props) {
  return (
    <FieldDecoration {...props}>
      <NumberFieldPlain {...props} />
    </FieldDecoration>
  );
}
