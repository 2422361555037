import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { logger } from './logger';
import { DEBUG } from './constants';
import en from '../localization/en.json';

class LoggerAdapter {
  constructor() {
    this._logger = logger.child({ name: 'i18next' });
  }

  log([msg, arg]) {
    this._logger.debug({ details: arg }, msg);
  }

  warn([msg, arg]) {
    this._logger.warn({ details: arg }, msg);
  }

  error([msg, arg]) {
    this._logger.error({ details: arg }, msg);
  }
}

LoggerAdapter.type = 'logger';

// eslint-disable-next-line react-hooks/rules-of-hooks
use(LoggerAdapter)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: DEBUG,
    resources: { en },
    ns: ['common'],
    defaultNS: 'common',
  });
