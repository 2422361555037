import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useUpdateSlotStatus() {
  const dispatch = useDispatch();
  const updateSlotStatus = useApiPost('calendar/updateallslotstatusesbyslots');
  return useMemo(
    () => ({
      ...updateSlotStatus,
      execute: async ({ calendarSlotId, status, reason, description }) => {
        const body = {
          SlotIds: [calendarSlotId],
          SlotStatus: status,
        };
        if (status !== 'Available') {
          body.SlotStatusReason = reason;
          body.SlotStatusReasonDescription = description;
        }
        const updatedCalendarSlots = await updateSlotStatus.execute(body);
        for (const updatedCalendarSlot of updatedCalendarSlots) {
          dispatch(
            calendarActions.addCalendarSlot({
              listId: updatedCalendarSlot.calendarListId,
              calendarSlot: updatedCalendarSlot,
            }),
          );
        }
        return updatedCalendarSlots;
      },
    }),
    [updateSlotStatus, dispatch],
  );
}
