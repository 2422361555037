import memoize from 'memoize'; // eslint-disable-line import/no-unresolved
import invariant from 'tiny-invariant';

export class Matrix {
  constructor(array, width) {
    invariant(array.length % width === 0);
    this._width = width;
    this._height = Math.floor(array.length / width);
    this._data = [...array];
    invariant(this._data.length === this.width * this._height);
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get(x, y) {
    const index = x + y * this._width;
    invariant(index >= 0 && index < this._data.length);
    return this._data[index];
  }

  set(x, y, value) {
    const index = x + y * this._width;
    invariant(index >= 0 && index < this._data.length);
    this._data[index] = value;
  }

  toString() {
    let result = '';
    const w = this._data.reduce((acc, val) => Math.max(acc, val?.length ?? 0), 'null'.length);
    for (let y = 0; y < this._height; ++y) {
      result += '[ ';
      result += this._data
        .slice(y * this._width, (y + 1) * this._width)
        .map((x) => (x === undefined ? '' : x === null ? 'null' : x).padEnd(w, ' '))
        .join(' | ');
      result += ' ]\n';
    }
    return result;
  }

  clone() {
    return new Matrix(this._data, this._width);
  }

  hash() {
    return JSON.stringify(this._data) + this._width;
  }
}

export const mergeMatrixCells = memoize(mergeMatrixCellsInternal, {
  maxAge: 600000,
  cacheKey: ([arg]) => arg.hash(),
});

// This function generates a list of merged cells given a matrix of strings.
// Each cell containing a string is extended down and then to the right if the
// adjacent cell contain the same string (or undefined).
function mergeMatrixCellsInternal(matrix_) {
  const matrix = matrix_.clone();

  const overlays = [];
  for (let x = 0; x < matrix.width; ++x) {
    for (let y = 0; y < matrix.height; ++y) {
      const reason = matrix.get(x, y);
      if (typeof reason === 'string') {
        let w = 1;
        let h = 1;

        // Try grow area vertically
        for (let y2 = y + 1; y2 < matrix.height; ++y2) {
          const adjacentReason = matrix.get(x, y2);
          const mergeCellBelow = adjacentReason === reason || adjacentReason === undefined;
          if (mergeCellBelow) {
            h += 1;
            matrix.set(x, y2, null);
          }
        }

        // Try grow area horizontally
        for (let x2 = x + 1; x2 < matrix.width; ++x2) {
          const mergeCellsAside = Array.from(new Array(h).keys()).every((o) => {
            const adjacentReason = matrix.get(x2, y + o);
            return adjacentReason === reason || (o > 0 && adjacentReason === undefined);
          });
          if (mergeCellsAside) {
            w += 1;
            for (let o = 0; o < h; ++o) matrix.set(x2, y + o, null);
          }
        }

        overlays.push({ x, y, w, h, reason });
      }
    }
  }

  return overlays;
}
