import { useCallback, useRef } from 'react';
import { useVersion } from '../api/store/health';
import { Button } from '../components/button';
import { triggerToast } from '../components/toast';
import { yup } from '../utils';
import { useLocation } from 'react-router';

const TOAST_ID = 'soopa-updated-toast';
const ONE_HOUR = 3600000;

export function useUpdateChecker() {
  const stateRef = useRef({
    toastedVersion: undefined,
    toastedTime: 0,
    toastId: undefined,
  });
  const { current, currentNumber, next, nextNumber } = useVersion();
  const location = useLocation();

  const triggerUpdateToast = useCallback(
    (props) => {
      const onUpdateClick = () =>
        (window.location = location.pathname + location.search + location.hash);
      triggerToast('soopa', <ToastContent {...props} onUpdateClick={onUpdateClick} />, {
        id: TOAST_ID,
        duration: Infinity,
      });
    },
    [location.hash, location.pathname, location.search],
  );

  if (
    currentNumber > 0 &&
    nextNumber > 0 &&
    currentNumber < nextNumber &&
    (stateRef.current.toastedVersion !== next ||
      Date.now() - stateRef.current.toastedTime > ONE_HOUR)
  ) {
    stateRef.current.toastedVersion = next;
    stateRef.current.toastedTime = Date.now();
    setTimeout(() => triggerUpdateToast({ currentVersion: current, nextVersion: next }), 0);
  }
}

function ToastContent({ nextVersion, onUpdateClick }) {
  return (
    <>
      <p className="font-medium text-gray-900">Version {nextVersion} available!</p>
      <p className="mt-2 text-sm text-gray-500">
        Great news! An updated version of SOOPA is now available. Click the update button below or
        refresh the page at your convenience to get the updated version.
      </p>
      <p className="mt-3 text-sm text-medium flex justify-start">
        <Button label="Update" onClick={onUpdateClick} variant="filled" className="w-full" />
      </p>
    </>
  );
}

ToastContent.propTypes = {
  nextVersion: yup.string().required().pt(),
  onUpdateClick: yup.mixed().callback().pt(),
};
