import { useFormState } from 'react-hook-form';
import { TextFieldPlain } from '../form/text-field';
import { Td } from './shared';

export function PatientDetailsFormFields() {
  const { isSubmitting, isSubmitted, isLoading } = useFormState();
  const disabled = isSubmitting || isSubmitted || isLoading;
  return (
    <>
      <Td className="uppercase bg-gray-50">
        <TextFieldPlain field="nhiNumber" placeholder="NHI" errorTooltip disabled />
      </Td>
      <Td className="uppercase bg-gray-50">
        <TextFieldPlain
          field="nameFamily"
          placeholder="Family name"
          errorTooltip
          disabled={disabled}
        />
      </Td>
      <Td className="bg-gray-50">
        <TextFieldPlain
          field="nameGiven"
          placeholder="Given name"
          errorTooltip
          disabled={disabled}
        />
      </Td>
      <Td className="bg-gray-50">
        <TextFieldPlain field="phoneNumber" placeholder="Phone" errorTooltip disabled={disabled} />
      </Td>
      <Td className="bg-gray-50">
        <TextFieldPlain field="email" placeholder="Email" errorTooltip disabled={disabled} />
      </Td>
    </>
  );
}
