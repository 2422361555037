import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Button, ButtonGroup } from './button';
import { useRelativeDateGeneratorFromUrl } from '../hooks/use-relative-date-generator';
import { PractitionerListBox } from './practitioner-list-box';
import { urlJoin } from '../utils';
import { useCallback, useMemo } from 'react';

export function DashboardNavigation() {
  const location = useLocation();
  const [params] = useSearchParams();

  return (
    <div className="grow-0 shrink-0 flex flex-nowrap">
      <DashboardFilterForm />
      <div className="ml-5">
        <DayNavigation />
      </div>
      <div className="ml-5">
        <JumpNavigation />
      </div>
      <div className="ml-5 xl:ml-10">
        <Button
          variant="filled"
          to={urlJoin(location.pathname, 'new', params)}
          icon={PlusIcon}
          label="New appointment"
          labelClassName="sr-only xl:not-sr-only"
          testId="navigate-new-appointment"
        />
      </div>
      <div className="ml-5">
        <Button
          variant="outline"
          color="gray"
          to={urlJoin(location.pathname, 'search', params)}
          icon={MagnifyingGlassIcon}
          label="Search"
          labelClassName="sr-only"
          testId="navigate-search"
        />
      </div>
    </div>
  );
}

function DashboardFilterForm() {
  const location = useLocation();
  const navigate = useNavigate();

  const changeHandler = useCallback(
    (practitioner) => {
      const url = urlJoin(location.pathname, createSearchParams({ practitioner }));
      navigate(url);
    },
    [location.pathname, navigate],
  );

  const practitioners = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.getAll('practitioner').toSorted();
  }, [location.search]);

  return (
    <div className="w-48">
      <PractitionerListBox multiple onChange={changeHandler} value={practitioners} />
    </div>
  );
}

function DayNavigation() {
  const location = useLocation();
  const [params] = useSearchParams();
  const generator = useRelativeDateGeneratorFromUrl();
  const previousDay = generator.at(-1);
  const nextDay = generator.at(1);
  return (
    <ButtonGroup variant="outline" color="gray">
      <Button
        to={urlJoin(location.pathname, previousDay.href, params)}
        icon={ChevronLeftIcon}
        label="Previous day"
        labelClassName="sr-only"
      />
      <Button label={generator.base.t.format('ddd, MMM D')} className="w-32" />
      <Button
        to={urlJoin(location.pathname, nextDay.href, params)}
        icon={ChevronRightIcon}
        label="Next day"
        labelClassName="sr-only"
      />
    </ButtonGroup>
  );
}

function JumpNavigation() {
  const location = useLocation();
  const [params] = useSearchParams();
  const generator = useRelativeDateGeneratorFromUrl();
  const previousMonth = generator.at(-1, 'month');
  const previousWeek = generator.at(-1, 'week');
  const nextWeek = generator.at(1, 'week');
  const nextMonth = generator.at(1, 'month');
  return (
    <ButtonGroup variant="outline" color="gray">
      <Button to={urlJoin(location.pathname, previousMonth.href, params)} label="-1M" />
      <Button to={urlJoin(location.pathname, previousWeek.href, params)} label="-1W" />
      <Button
        to={urlJoin(location.pathname, generator.today.href, params)}
        label={generator.today.name}
      />
      <Button to={urlJoin(location.pathname, nextWeek.href, params)} label="+1W" />
      <Button to={urlJoin(location.pathname, nextMonth.href, params)} label="+1M" />
    </ButtonGroup>
  );
}
