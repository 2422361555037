import { Fragment, cloneElement, createElement, useState, useMemo } from 'react';
import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { matchSorter } from 'match-sorter';
import { yup } from '../../utils';
import { useController } from 'react-hook-form';
import { ErrorIcon } from './error-message';
import { EMPTY_OBJECT } from '../../constants';
import clsx from 'clsx';
import { flip, offset, useFloating } from '@floating-ui/react-dom';
import { FieldDecoration } from './field-decoration';

export function ComboBoxPlain({
  field,
  placeholder = '',
  disabled = false,
  decorated = false,
  options = EMPTY_OBJECT,
  classes = EMPTY_OBJECT,
  testId,
}) {
  const { field: inputProps, fieldState } = useController({ name: field });
  const [query, setQuery] = useState('');
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(4), flip()],
  });
  const error = fieldState.error?.message;
  const filteredSortedAndMappedOptions = useMemo(() => {
    const filteredOptions =
      query.length > 0
        ? matchSorter(options, query, {
            keys: [(item) => [...(item.props['data-search']?.split('||') ?? []), item.props.label]],
          })
        : options;

    if (filteredOptions.length === 0) {
      return [
        <HeadlessCombobox.Option
          key="no-options"
          value="no-options"
          disabled={true}
          className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-400"
        >
          No matching items
        </HeadlessCombobox.Option>,
      ];
    }

    return filteredOptions.map((option) => (
      <HeadlessCombobox.Option
        key={option.key}
        className={({ active }) =>
          clsx(
            { 'bg-indigo-50': active },
            'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
          )
        }
        value={option.key}
      >
        {({ selected, active }) => cloneElement(option, { selected, active })}
      </HeadlessCombobox.Option>
    ));
  }, [options, query]);

  return (
    <HeadlessCombobox
      {...inputProps}
      onBlur={(e) => {
        // Don't trigger blur if we are refocusing to a different child element
        // of the listbox.
        if (
          typeof inputProps.onBlur === 'function' &&
          !refs.reference.current?.contains(e.relatedTarget)
        ) {
          inputProps.onBlur(e);
        }
      }}
      name={undefined}
      disabled={disabled}
    >
      {({ open }) => {
        return (
          <div ref={refs.setReference} className="relative min-w-[54px]" data-testid={testId}>
            <HeadlessCombobox.Input
              id={field}
              className={clsx(
                'w-full px-2.5 py-1.5 truncate border rounded-md shadow-sm focus:outline-none sm:text-sm disabled:opacity-50',
                error
                  ? 'text-error-500 border-error-500  placeholder:text-error-300 focus:ring-error-500 focus:border-error-500 disabled:ring-error-500 pr-[44px]'
                  : 'text-gray-900 border-gray-300 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-500 focus:border-indigo-500 pr-[22px]',
              )}
              placeholder={placeholder}
              onChange={(e) => setQuery(e.target.value)}
              displayValue={(option) => {
                const optionItem = options.find((o) => o.key === option);
                return optionItem ? optionItem.props.label : '';
              }}
            />
            {error && !decorated && <ErrorIcon error={error} className="mr-6" />}
            <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </HeadlessCombobox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessCombobox.Options
                ref={refs.setFloating}
                style={floatingStyles}
                className={clsx(
                  'z-20 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                  classes.comboBoxOptions,
                )}
                data-testid={`${field}-options`}
              >
                {filteredSortedAndMappedOptions}
              </HeadlessCombobox.Options>
            </Transition>
          </div>
        );
      }}
    </HeadlessCombobox>
  );
}

ComboBoxPlain.propTypes = {
  field: yup.string().required().pt(),
  placeholder: yup.string().pt(),
  disabled: yup.boolean().pt(),
  decorated: yup.boolean().pt(),
  options: yup.mixed().react().pt(),
  classes: yup
    .object({
      comboBoxOptions: yup.string(),
    })
    .pt(),
  testId: yup.string().pt(),
};

export function ComboBox(props) {
  return (
    <FieldDecoration {...props}>
      <ComboBoxPlain {...props} />
    </FieldDecoration>
  );
}

function ComboBoxItem({ selected, children }) {
  return (
    <>
      <div className="flex items-center">{children}</div>
      {selected ? (
        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      ) : null}
    </>
  );
}

ComboBoxItem.propTypes = {
  selected: yup.boolean().pt(),
  children: yup.mixed().react().pt(),
};

function ComboBoxLabelItem({ label, icon, selected }) {
  return (
    <ComboBoxItem selected={selected}>
      <div className="flex items-center">
        {icon
          ? createElement(icon, {
              className: 'h-5 w-5 flex-shrink-0',
              'aria-hidden': true,
            })
          : null}
        <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
          {label}
        </span>
      </div>
    </ComboBoxItem>
  );
}

ComboBoxLabelItem.propTypes = {
  selected: yup.boolean().pt(),
  label: yup.string().required().pt(),
  icon: yup.mixed().react().pt(),
};

ComboBox.Item = ComboBoxItem;
ComboBox.LabelItem = ComboBoxLabelItem;

export function LoadingComboBox(props) {
  return <ComboBox {...props} options={[]} disabled={true} placeholder="Loading" />;
}
