import { useMemo } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { ListBox, ListBoxPlain, LoadingListBox } from './form/list-box';
import { yup } from '../utils';
import { usePractitionersState } from '../api/store/practitioners';
import { PractitionerAvatar } from './avatar';
import { Button } from './button';

export function PractitionerListBox({ field, onChange, ...props }) {
  const state = usePractitionersState();
  const options = useMemo(
    () => [
      <ListBox.UnselectableItem key="buttons">
        <div disabled className="flex justify-stretch flex-nowrap w-full space-x-2 px-2 py-1.5">
          <Button
            variant="outline"
            size="small"
            color="gray"
            label="All"
            className="flex-1"
            onClick={() => onChange(Object.keys(state.data))}
          />
          <Button
            variant="outline"
            size="small"
            color="gray"
            label="None"
            className="flex-1"
            onClick={() => onChange([])}
          />
        </div>
      </ListBox.UnselectableItem>,
      ...makePractitionerListBoxOptions(state.data),
    ],
    [onChange, state.data],
  );
  return state.suspend ? (
    <LoadingListBox field={field} />
  ) : (
    <ListBoxPlain
      {...props}
      onChange={onChange}
      field={field}
      options={options}
      placeholder="Select practitioner"
      SelectedComponent={SelectedPractitioners}
    />
  );
}

PractitionerListBox.propTypes = {
  field: yup.string().required().pt(),
  onChange: yup.mixed().callback().pt(),
};

function SelectedPractitioners({ value: values, options }) {
  return useMemo(() => {
    const numOptions = options.reduce(
      (acc, val) => (val.type === ListBox.UnselectableItem ? acc : acc + 1),
      0,
    );
    const selectedOptions = values ? options.filter((x) => values.includes(x.key)) : [];
    const numSelected = selectedOptions.length;
    if (numSelected === numOptions || numSelected === 0) {
      return <ListBox.LabelItem key="all" label="All" icon={UserGroupIcon} />;
    } else if (numSelected === 1) {
      return selectedOptions[0];
    } else {
      const label = `Multiple (${numSelected}/${numOptions})`;
      return <ListBox.LabelItem key={label} label={label} icon={UserGroupIcon} />;
    }
  }, [options, values]);
}

export function makePractitionerListBoxOptions(practitioners) {
  return [
    ...Object.values(practitioners)
      .toSorted((a, b) => a.nameText.localeCompare(b.nameText))
      .map((practitioner) => (
        <ListBox.Item key={practitioner.id}>
          <div className="flex flex-row items-center ui-selected:font-bold">
            <PractitionerAvatar className="size-5" practitioner={practitioner} />
            <span className="ml-2 text-gray-900">{practitioner.nameText}</span>
          </div>
        </ListBox.Item>
      )),
  ];
}
