import { yup } from '../utils';

export const TIME_SCHEMA = yup.string().matches(/^\d\d:\d\d:\d\d$/);
export const DATE_SCHEMA = yup.string().matches(/^\d\d\d\d-\d\d-\d\d$/);

export const PRACTITIONER_SCHEMA = yup.object({
  id: yup.string().required(),
  nameText: yup.string().required(),
  avatarUrl: yup.string(),
});

export const APPOINTMENT_SCHEMA = yup.object({
  id: yup.string(),
});

export const PROCEDURE_SCHEMA = yup.object({
  id: yup.string(),
});

export const SLOT_SCHEMA = yup.object({
  id: yup.string().required(),
  slotNumber: yup.number().required(),
  arrivalTime: TIME_SCHEMA.required(),
  procedure: PROCEDURE_SCHEMA.nullable(),
  appointment: APPOINTMENT_SCHEMA.nullable(),
});

export const LIST_SCHEMA = yup.object({
  date: DATE_SCHEMA.required(),
  sessionTime: yup.string().oneOf(['AllDay', 'Morning', 'Afternoon']).required(),
  calendarSlots: yup.array().of(SLOT_SCHEMA).required(),
  practitioner: PRACTITIONER_SCHEMA.required(),
  listType: yup.string(),
});

export const ROOM_SCHEMA = yup.object({
  shortDescription: yup.string().required(),
  lists: yup.object({
    morning: LIST_SCHEMA.nullable().default(null),
    afternoon: LIST_SCHEMA.nullable().default(null),
  }),
});
