import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { SidePanelContent, SidePanelTitle } from '../../../lib/components/side-panel-outlet';
import { Button } from '../../../lib/components/button';
import { ArrowPathIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { Tabs } from '../../../lib/components/tabs';
import { useList } from '../../../lib/api/store/calendar';
import { PractitionerAvatar } from '../../../lib/components/avatar';
import { yup } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { ListScoreBadge } from '../../../lib/components/list-score-badge';
import { DateTime } from '../../../lib/components/date-time';
import { AppointmentsTable } from '../../../lib/components/appointments-table/appointments-table';
import { LoadingSpinner } from '../../../lib/components/loading-spinner';
import { ListPreferences } from '../../../lib/components/list-preferences';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useLatest } from 'react-use';

export function ListDetails() {
  const { t } = useTranslation();
  const { listId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const state = useList(listId);
  const list = state.data;

  const selectedTab = useMemo(() => {
    const parts = location.pathname.split('/');
    return parts.at(-3) === 'details' ? parts.at(-1) : 'unknown';
  }, [location.pathname]);
  const latestSelectedTab = useLatest(selectedTab);

  const selectedIndex = useMemo(() => {
    switch (selectedTab) {
      case 'bookings':
        return 0;
      case 'preferences':
        return 1;
      default:
        return -1;
    }
  }, [selectedTab]);

  const onTabChange = useCallback(
    (selectedIndex) => {
      if (latestSelectedTab.current !== 'unknown') {
        if (selectedIndex === 0) {
          navigate('../bookings');
        } else if (selectedIndex === 1) {
          navigate('../preferences');
        }
      }
    },
    [latestSelectedTab, navigate],
  );

  return !state.suspend && list ? (
    <SidePanelContent header={<SidePanelTitle title={list.room.longDescription} />} className="p-6">
      <div className="flex flex-row justify-between mb-2" data-testid="list-details">
        <h1 className="text-xl font-semibold">
          <DateTime t={list.date} format="dddd, MMMM D, YYYY" /> -{' '}
          {t(list.sessionTime.toLowerCase())}
        </h1>
        <Button
          variant="outline"
          color="gray"
          icon={PrinterIcon}
          label="Print"
          to="."
          className="invisible"
        />
      </div>
      <div className="flex flex-row justify-start items-center" data-testid="list-summary">
        <PractitionerAvatar practitioner={list.practitioner} className="size-8" />
        <h3 className="truncate max-w-full text-sm font-semibold text-gray-600 ml-2">
          {list.practitioner.nameText}
        </h3>
        <div className="ml-6" data-testid="score-badge">
          <ListScoreBadge list={list} />
        </div>
        <div className="mx-6 text-gray-300">|</div>
        <WebpasSyncStatus list={list} />
      </div>
      <div className="w-full mt-8 sm:px-0">
        {selectedIndex < 0 ? (
          <Navigate to="bookings" replace />
        ) : (
          <Tabs manual selectedIndex={selectedIndex} onChange={onTabChange}>
            <Tabs.Panel label="Bookings" to="../bookings">
              <AppointmentsTable list={list} />
            </Tabs.Panel>
            <Tabs.Panel label="Preferences" to="../preferences">
              <ListPreferences list={list} />
            </Tabs.Panel>
          </Tabs>
        )}
      </div>
    </SidePanelContent>
  ) : (
    <div className="text-gray-400 h-full w-full flex justify-center items-center">
      <LoadingSpinner />
    </div>
  );
}

ListDetails.propTypes = {};

function WebpasSyncStatus({ list }) {
  const synced = list.syncedSlots;
  const syncable = list.syncableSlots;
  const colour = synced >= syncable ? 'text-success-700' : 'text-error-700';
  return (
    <h3 className="truncate text-sm font-normal text-gray-600" data-testid="sync-status">
      <ArrowPathIcon className={clsx(colour, 'size-6 inline')} />
      <span className={clsx(colour, 'mx-1.5 font-semibold')}>
        {synced}/{syncable}
      </span>
      synced with WebPAS
    </h3>
  );
}

WebpasSyncStatus.propTypes = {
  list: yup
    .object({
      scheduleSlots: yup.array().of(
        yup.object({
          webpasSynced: yup.boolean().required(),
        }),
      ),
    })
    .pt(),
};
