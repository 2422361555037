import { useMemo } from 'react';
import stringify from 'safe-stable-stringify';
import { Matrix, mergeMatrixCells } from './utils';
import { yup, urlJoin } from '../../../lib/utils';
import { DateTime } from '../../../lib/components/date-time';
import { useLists } from '../../../lib/api/store/calendar';
import { Link, useSearchParams } from 'react-router';
import { PractitionerAvatar } from '../../../lib/components/avatar';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { ListScoreBadge } from '../../../lib/components/list-score-badge';
import { useProcedureCode } from '../../../lib/api/store/procedures';
import { DATE_SCHEMA, LIST_SCHEMA, ROOM_SCHEMA, SLOT_SCHEMA } from '../../../lib/api/schemas';
import clsx from 'clsx';

export function DayBlock({ t }) {
  const lists = useLists(t);
  const isEmpty = Object.keys(lists.data?.rooms ?? {}).length === 0;
  return lists.suspend ? (
    <DayBlockLoading t={t} />
  ) : isEmpty ? (
    <DayBlockEmpty t={t} />
  ) : (
    <DayBlockLoaded t={t} lists={lists.data} />
  );
}

function DayBlockLoading({ t }) {
  // TODO: estimate width when loaded to avoid layout shift
  return (
    <div className="h-full min-h-full max-h-full w-[20rem] border-gray-300 border border-dashed rounded-md p-2 bg-white">
      <DateTime t={t} format="ddd, MMM D" className="block text-gray-400 text-center mb-3" />
    </div>
  );
}

function DayBlockEmpty({ t }) {
  const dow = t.day();
  return dow === 0 || dow === 6 ? (
    <div className="h-full min-h-full max-h-full w-8"></div>
  ) : (
    <div className="h-full min-h-full max-h-full rounded-md p-2 bg-gray-300 text-gray-800 w-[9rem]">
      <div>
        <DateTime t={t} format="ddd, MMM D" className="block text-center mb-3" />
      </div>
    </div>
  );
}

function getListReason(list) {
  return list ? (list.blockedReason ?? null) : undefined;
}

function makeBlockedReasonMatrix(rooms) {
  const row1 = [];
  const row2 = [];
  for (const room of rooms) {
    row1.push(getListReason(room.lists.alldayList ?? room.lists.morning));
    row2.push(getListReason(room.lists.alldayList ?? room.lists.afternoon));
  }
  return new Matrix([...row1, ...row2], rooms.length);
}

function DayBlockLoaded({ t, lists }) {
  const rooms = Object.values(lists.rooms);
  const numberOfRooms = rooms.length ?? 0;

  const elements = useMemo(() => {
    const sorted = rooms.toSorted((a, b) => a.shortDescription.localeCompare(b.shortDescription));
    const overlays = mergeMatrixCells(makeBlockedReasonMatrix(sorted));
    return [
      ...sorted.map((room, index) => <RoomBlock key={room.id} t={t} index={index} room={room} />),
      ...overlays.map((props) => <CalendarBlock key={JSON.stringify(props)} {...props} />),
    ];
  }, [rooms, t]);

  return (
    <div
      className="flow-col-max-full rounded-md p-2 bg-gray-300 text-gray-800 h-full min-h-full max-h-full data-[rooms=1]:w-[10rem] data-[rooms=2]:w-[20rem] data-[rooms=3]:w-[30rem] data-[rooms=4]:w-[40rem]"
      data-rooms={numberOfRooms}
    >
      <div className="flex-center h-[2rem] mb-[0.4rem]">
        <DateTime t={t} format="ddd, MMM D" className="block flex-none text-center" />
      </div>
      <div
        className="flex-1 h-full max-h-[calc(100%-2.4rem)] grid gap-x-2 data-[rooms=1]:grid-cols-1 data-[rooms=2]:grid-cols-2 data-[rooms=3]:grid-cols-3 data-[rooms=4]:grid-cols-4 grid-rows-[calc(50%-4px)_8px_calc(50%-4px)]"
        data-rooms={numberOfRooms}
      >
        {elements}
      </div>
    </div>
  );
}

function RoomBlock({ t, index, room }) {
  const alldayList = room.lists.allday;
  const morningList = room.lists.morning;
  const afternoonList = room.lists.afternoon;
  if (alldayList) {
    return (
      <div
        className="flow-col-max-full bg-gray-100 rounded-md p-1 row-start-1 row-span-3 data-[room=0]:col-start-1 data-[room=1]:col-start-2 data-[room=2]:col-start-3 data-[room=3]:col-start-4 data-[blocked=true]:saturate-0"
        data-room={index}
        data-blocked={!!alldayList.blockedReason}
      >
        <h2 className="flex-center h[1.6rem] my-[0.2rem] flex-none text-center font-medium text-gray-600">
          {room.shortDescription}
        </h2>
        <ListBlock t={t} list={alldayList} />
      </div>
    );
  } else {
    return (
      <>
        <div
          className="flow-col-max-full bg-gray-100 rounded-t-md p-1 row-span-1 row-start-1 data-[room=0]:col-start-1 data-[room=1]:col-start-2 data-[room=2]:col-start-3 data-[room=3]:col-start-4 data-[blocked=true]:saturate-50"
          data-room={index}
          data-blocked={!!morningList?.blockedReason}
        >
          <h2 className="flex-center h[1.6rem] my-[0.2rem] flex-none text-center font-medium text-gray-600">
            {room.shortDescription}
          </h2>
          {morningList ? <ListBlock t={t} list={morningList} /> : <EmptyList />}
        </div>
        <div
          className="bg-gray-100 row-start-2 row-span-1 data-[room=0]:col-start-1 data-[room=1]:col-start-2 data-[room=2]:col-start-3 data-[room=3]:col-start-4"
          data-room={index}
        >
          <div className="block mt-[3px] pb-[1px] border-t border-dashed border-gray-700"></div>
        </div>
        <div
          className="flow-col-max-full bg-gray-100 rounded-b-md p-1 row-start-3 row-span-1 data-[room=0]:col-start-1 data-[room=1]:col-start-2 data-[room=2]:col-start-3 data-[room=3]:col-start-4 data-[blocked=true]:saturate-50"
          data-room={index}
          data-blocked={!!afternoonList?.blockedReason}
        >
          {afternoonList ? <ListBlock t={t} list={afternoonList} /> : <EmptyList />}
        </div>
      </>
    );
  }
}

function CalendarBlock({ x, y, w, h, reason }) {
  return (
    <div
      className="z-10 w-full h-full flex justify-center items-center bg-gray-300/70 data-[x=0]:col-start-1 data-[x=1]:col-start-2 data-[x=2]:col-start-3 data-[x=3]:col-start-4 data-[y=0]:row-start-1 data-[y=1]:row-start-2 data-[w=1]:col-end-[_span_1] data-[w=2]:col-end-[_span_2] data-[w=3]:col-end-[_span_3] data-[w=4]:col-end-[_span_4] data-[h=1]:row-end-[_span_2] data-[h=2]:row-end-[_span_3]"
      data-x={x}
      data-y={y}
      data-w={w}
      data-h={h}
    >
      <div className="bg-gray-900 text-white m-4 px-2 py-4 w-full uppercase rounded text-xs font-bold text-center leading-5">
        {reason}
      </div>
    </div>
  );
}

function useShouldShowList(list) {
  const [searchParams] = useSearchParams();
  const filteredPractitioners = searchParams.getAll('practitioner');
  return useMemo(() => {
    const practitioner = list.practitioner.id;
    return filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify(filteredPractitioners), list.practitioner.id]);
}

function EmptyList() {
  return <div></div>;
}

function ListBlock({ list }) {
  const [params] = useSearchParams();
  const path = `details/${list.id}`;
  const show = useShouldShowList(list);
  const slots = useMemo(() => {
    const sorted = list.calendarSlots.toSorted(
      (a, b) => a.procedureSlotNumber - b.procedureSlotNumber,
    );
    return sorted.map((slot) => <ListSlot key={slot.id} slot={slot} />);
  }, [list]);
  const isWebpasSynced = list?.syncedSlots === list?.syncableSlots;
  return list ? (
    <Link
      to={urlJoin(path, params)}
      className={clsx(
        'max-h-[calc(100%-2rem)] basis-1 shrink flex flex-col justify-stretch bg-white rounded-md p-1 p-1 cursor-pointer',
        {
          invisible: !show,
          'border-2 border-red-600': !isWebpasSynced,
        },
      )}
      data-list-date={list.date}
      data-list-room={list.room.shortDescription.toLowerCase()}
      data-list-session={list.sessionTime.toLowerCase()}
    >
      <div className="flex-none flex-center h-[5.5rem]">
        <div>
          <div className="flex flex-row items-center space-x-2 pb-1">
            <PractitionerAvatar practitioner={list.practitioner} className="size-9" />
            <ListTypeIcon list={list} />
          </div>
        </div>
        <h3 className="truncate max-w-full font-medium text-gray-700">
          {list.practitioner.nameText}
        </h3>
      </div>
      <div className="flex-1 flow-col-max-full overflow-y-hidden gap-y-[3px] max-h-[calc(100%-7.75rem)]">
        {slots}
      </div>
      <div className="flex-none flex-center h-[2rem] mt-[0.25rem]">
        <div className="space-x-2 flex">
          {!list.blockedReason ? <ListScoreBadge list={list} /> : null}
          {!isWebpasSynced ? <ArrowPathIcon className="size-5 text-error-700" /> : null}
        </div>
      </div>
    </Link>
  ) : (
    <EmptyList />
  );
}

function ListSlot({ slot }) {
  if (slot.appointment) {
    return <FilledListSlot slot={slot} />;
  } else if (slot.status === 'Unavailable') {
    return <UnavailableListSlot slot={slot} />;
  } else {
    return <AvailableListSlot slot={slot} />;
  }
}

const SLOT_BASE_STYLE =
  'basis-[1.6rem] grow shrink max-h-7 flex flex-col justify-center items center text-center text-[0.8rem] leading-4';
const ADDED_SLOT_STYLE = 'border-orange-400 border border-dashed';

function FilledListSlot({ slot }) {
  let style = 'shaded-gray text-gray-400';
  if (slot.appointment.status === 'Proposed') {
    style = 'shaded-pink';
  } else if (slot.appointment.status === 'Pending') {
    style = 'shaded-orange';
  }
  const procedure = useProcedureCode(slot.appointment.procedure?.procedureCode?.id);
  return (
    <div className={clsx(SLOT_BASE_STYLE, style, slot.isManualEntry && ADDED_SLOT_STYLE)}>
      {procedure?.procedureCategory?.shortDisplay ?? <span>&nbsp;</span>}
    </div>
  );
}

function UnavailableListSlot({ slot }) {
  return (
    <div
      className={clsx(
        SLOT_BASE_STYLE,
        'text-gray-400 line-through striped-gray-bg',
        slot.isManualEntry && ADDED_SLOT_STYLE,
      )}
    >
      <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
    </div>
  );
}

function AvailableListSlot({ slot }) {
  return (
    <div className={clsx(SLOT_BASE_STYLE, 'shaded-red', slot.isManualEntry && ADDED_SLOT_STYLE)}>
      <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
    </div>
  );
}

function ListTypeIcon({ list }) {
  switch (list.listType) {
    case 'Propofol':
      return <StarIcon className="text-sky-400 size-6" />;
    default:
      return null;
  }
}

DayBlock.propTypes = {
  t: yup.mixed().dayjs().required().pt(),
};

DayBlockEmpty.propTypes = {
  t: DayBlock.propTypes.t,
};

DayBlockLoading.propTypes = {
  t: DayBlock.propTypes.t,
};

DayBlockLoaded.propTypes = {
  t: DayBlock.propTypes.t,
  lists: yup
    .object({
      date: DATE_SCHEMA.required(),
      rooms: yup.object({}).required(),
    })
    .required()
    .pt(),
};

RoomBlock.propTypes = {
  t: DayBlock.propTypes.t,
  index: yup.number().pt(),
  room: ROOM_SCHEMA.required().pt(),
};

CalendarBlock.propTypes = {
  x: yup.number().integer().required().pt(),
  y: yup.number().integer().required().pt(),
  w: yup.number().integer().required().pt(),
  h: yup.number().integer().required().pt(),
  reason: yup.string().required().pt(),
};

ListBlock.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};

ListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

FilledListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

UnavailableListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

AvailableListSlot.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};

ListTypeIcon.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};
