import { useMemo } from 'react';
import { useLatest } from 'react-use';
import { yup } from '../utils';
import { LoadingListBox, ListBox, ListBoxPlain } from './form/list-box';
import { useEnumState } from '../api/store/enums';

export function EnumListBox(props) {
  return <EnumListBoxInternal ListBoxComponent={ListBox} {...props} />;
}

export function EnumListBoxPlain(props) {
  return <EnumListBoxInternal ListBoxComponent={ListBoxPlain} {...props} />;
}

export function EnumListBoxInternal({
  name,
  field,
  valuesToOmit = [],
  makeEnumListBoxOptions = defaultMakeEnumListBoxOptions,
  ListBoxComponent,
  ...props
}) {
  const state = useEnumState(name);
  const options = useEnumListBoxOptions(state.data ?? [], makeEnumListBoxOptions, valuesToOmit);
  if (state.suspend) {
    return <LoadingListBox {...props} field={field} />;
  } else {
    return <ListBoxComponent {...props} field={field} options={options} />;
  }
}

EnumListBoxInternal.propTypes = {
  name: yup.string().required().pt(),
  field: yup.string().required().pt(),
  valuesToOmit: yup.array().of(yup.string()).pt(),
  makeEnumListBoxOptions: yup.mixed().callback().pt(),
  ListBoxComponent: yup.mixed().react().required().pt(),
};

function useEnumListBoxOptions(enums, makeEnumListBoxOptions, valuesToOmit) {
  const latestMakeOptions = useLatest(makeEnumListBoxOptions);
  return useMemo(() => {
    return latestMakeOptions.current(
      Object.fromEntries(Object.entries(enums).filter(([k]) => !valuesToOmit.includes(k))),
    );
  }, [enums, latestMakeOptions, valuesToOmit]);
}

export function defaultMakeEnumListBoxOptions(enumValues) {
  return Object.entries(enumValues).map(([k, v]) => <ListBox.LabelItem key={k} label={v} />);
}
