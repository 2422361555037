import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useUpdateList() {
  const dispatch = useDispatch();
  const updateList = useApiPost('schedule/createoraltersinglecalendarlist');
  return useMemo(
    () => ({
      ...updateList,
      execute: async ({
        listId,
        practitionerId,
        targetProcedurePoints,
        maximumProcedurePoints,
        listType,
      }) => {
        const body = {
          calendarListId: listId,
          practitionerId,
          targetProcedurePoints,
          maximumProcedurePoints,
          listType,
        };
        const updatedCalendarList = await updateList.execute(body);
        dispatch(calendarActions.updateCalendarList(updatedCalendarList));
        return updatedCalendarList;
      },
    }),
    [dispatch, updateList],
  );
}
