import dayjs from 'dayjs';
import { API_DATE_FORMAT, TIMEZONE } from '../../constants';
import invariant from 'tiny-invariant';

export function transformList(rawList) {
  const { calendarSlots, ...listWithoutCalendarSlots } = rawList;
  const list = {
    ...rawList,
    dateTimestamp: dayjs(rawList.date, API_DATE_FORMAT).tz(TIMEZONE, true).valueOf(),
    calendarSlots: Object.values(calendarSlots)
      .map((rawSlot) => transformCalendarSlot({ ...rawSlot, list: listWithoutCalendarSlots }))
      .toSorted(sortSlotsPredicate),
    syncedSlots: 0,
    syncableSlots: 0,
    filledSlots: 0,
    totalProcedurePoints: 0,
    blockedReason:
      rawList.status === 'Unavailable'
        ? rawList.statusReasonDescription
          ? rawList.statusReasonDescription
          : 'Unknown'
        : undefined,
  };

  for (const slot of list.calendarSlots) {
    list.syncedSlots += Number(!!slot.appointment?.isEnteredInWebPas);
    list.syncableSlots += Number(!!slot.appointment?.patientId);
    list.filledSlots += Number(!!slot.appointment);
    list.totalProcedurePoints += slot.appointment?.procedure?.procedureCode?.procedurePoints ?? 0;
  }

  list.pointStatus =
    list.totalProcedurePoints < list.targetProcedurePoints
      ? 'red'
      : list.totalProcedurePoints > list.maximumProcedurePoints
        ? 'orange'
        : 'green';

  return list;
}

export function transformCalendarSlot(rawSlot) {
  invariant(rawSlot.list);
  const listTimestamp = dayjs.utc(`${rawSlot.list.date}T00:00:00Z`).tz(TIMEZONE, true).valueOf();
  const arrivalTime = dayjs.utc(`${rawSlot.list.date}T${rawSlot.arrivalTime}Z`).tz(TIMEZONE, true);
  return {
    ...rawSlot,
    dayMilliseconds: arrivalTime.valueOf() - listTimestamp,
    arrivalTimeTimestamp: arrivalTime.valueOf(),
    appointment: rawSlot.appointment ? transformAppointment(rawSlot.appointment) : null,
  };
}

export function transformAppointment(rawAppointment) {
  invariant(rawAppointment.procedure);
  return { ...rawAppointment, procedure: transformProcedure(rawAppointment.procedure) };
}

export function transformProcedure(rawProcedure) {
  invariant(rawProcedure.procedureCode);
  return { ...rawProcedure, procedureCode: transformProcedureCode(rawProcedure.procedureCode) };
}

export function transformProcedureCode(procedureCode) {
  return {
    ...procedureCode,
    longDisplay:
      procedureCode.definition?.length > 0
        ? `${procedureCode.shortDisplay} (${procedureCode.definition})`
        : procedureCode.shortDisplay,
  };
}

export function transformProcedureCategory(procedureCategory) {
  return { ...procedureCategory };
}

export function transformProcedureCategoryGroup(procedureCategoryGroup) {
  return { ...procedureCategoryGroup };
}

export function transformPractitioner(practitioner) {
  return { ...practitioner };
}

export function sortSlotsPredicate(a, b) {
  const dt = a.dayMilliseconds - b.dayMilliseconds;
  const di = a.slotNumber - b.slotNumber;
  return dt !== 0 ? dt : di;
}

export function transformSearchResult(rawSearchResult) {
  const {
    calendarSlot: {
      calendarList: { practitioner, ...list },
      ...slot
    },
    ...searchResult
  } = rawSearchResult;
  searchResult.list = list;
  (searchResult.list.dateTimestamp = dayjs(list.date, API_DATE_FORMAT)
    .tz(TIMEZONE, true)
    .valueOf()),
    (searchResult.slot = slot);
  searchResult.practitioner = practitioner;
  return searchResult;
}
