import clsx from 'clsx';
import { yup } from '../utils';

export function Table({ className, children, ...props }) {
  return (
    <table
      className={clsx(
        'grid w-full text-gray-700 text-left font-normal text-sm items-center',
        className,
      )}
      {...props}
    >
      {children}
    </table>
  );
}

Table.propTypes = {
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};

export function Thead({ children }) {
  return <thead className="contents">{children}</thead>;
}

Thead.propTypes = {
  children: yup.mixed().react().pt(),
};

export function Tbody({ children }) {
  return <tbody className="contents">{children}</tbody>;
}

Tbody.propTypes = {
  children: yup.mixed().react().pt(),
};

export function Tr({ children, ...props }) {
  return (
    <tr className="contents group" {...props}>
      {children}
    </tr>
  );
}

Tr.propTypes = {
  children: yup.mixed().react().pt(),
};

export function Th({ className, children }) {
  return (
    <th
      className={clsx(
        className,
        'pl-3 py-4 bg-gray-50 uppercase tracking-wide text-left font-bold text-xs whitespace-nowrap overflow-hidden text-ellipsis',
      )}
    >
      {children}
    </th>
  );
}

Th.propTypes = {
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};

export function Td({ className, onClick, children, ...props }) {
  return (
    <td
      {...props}
      onClick={onClick}
      className={clsx(
        className,
        'pl-3 border-t border-gray-200 transition-colors h-16 min-w-0 flex items-center',
        { 'cursor-pointer group-hover:bg-indigo-50': onClick },
      )}
    >
      {children}
    </td>
  );
}

Td.propTypes = {
  className: yup.string().pt(),
  onClick: yup.mixed().callback().pt(),
  children: yup.mixed().react().pt(),
};
