import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { urlJoin, yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import { Td, TdArrivalTime, TdSlotNumber } from './shared';
import { useList } from '../../api/store/calendar';
import { useMemo, useCallback } from 'react';
import { ListBox, LoadingListBox } from '../form/list-box';
import { DateTime } from '../date-time';
import { Button } from '../button';
import { useMoveAppointment } from '../../api/actions/move-appointment';
import { useGenericErrorsToast } from '../toast';

const MOVE_SLOT_FORM_SCHEMA = yup.object().shape({
  calendarSlotId: yup.string().guid().required(),
});

export function FilledSlotRowMove({ index, slot, setMode }) {
  const moveSlotForm = useForm({
    resolver: yupResolver(MOVE_SLOT_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: { calendarSlotId: '' },
  });

  const { isSubmitting, isValid, isLoading } = moveSlotForm.formState;
  const disabled = !isValid || isSubmitting || isLoading;
  const genericErrorsToast = useGenericErrorsToast();

  const moveAppointment = useMoveAppointment();
  const onSubmit = useCallback(
    async (values) => {
      try {
        await moveAppointment.execute({
          appointmentId: slot.appointment.id,
          calendarSlotId: values.calendarSlotId,
        });
        setMode(0);
      } catch (error) {
        genericErrorsToast('Failed to move appointment', error);
      }
    },
    [genericErrorsToast, moveAppointment, setMode, slot.appointment.id],
  );

  return (
    <FormProvider {...moveSlotForm}>
      <tr className="group" data-testid={`slot-${index}`} data-slot-type="filled-move">
        <TdSlotNumber index={index} className="bg-gray-50" />
        <TdArrivalTime slot={slot} className="bg-gray-50" />
        <Td className="bg-gray-50" colSpan={5}>
          <div className="flex space-x-10 items-center">
            <form onSubmit={moveSlotForm.handleSubmit(onSubmit)} className="m-0 flex gap-x-2">
              <div className="w-44">
                <SelectEmptySlot slot={slot} />
              </div>
              <Button label="Move" type="submit" className="min-w-16" disabled={disabled} />
            </form>
            <span>OR</span>
            <Button
              to={urlJoin('..', 'move', slot.appointment.id)}
              variant="plain"
              label="MOVE TO ANOTHER LIST"
            />
          </div>
        </Td>
        <Td className="bg-gray-50" colSpan={5}>
          <div className="flex space-x-4">
            <div>{slot.appointment.procedure.procedureCode.longDisplay}</div>
            <div className="uppercase">{slot.appointment.patient?.nameFamily}</div>
          </div>
        </Td>
        <Td className="bg-gray-50 flex justify-end items-center pr-3">
          <Button
            icon={XMarkIcon}
            variant="plain"
            labelClassName="sr-only"
            onClick={() => setMode(0)}
          />
        </Td>
      </tr>
    </FormProvider>
  );
}

FilledSlotRowMove.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
};

export function SelectEmptySlot({ slot }) {
  const state = useList(slot.list.id);
  const options = useMemo(
    () => makeEmptySlotListBoxOptions(state.data?.calendarSlots ?? []),
    [state.data],
  );
  return state.suspend ? (
    <LoadingListBox field="calendarSlotId" />
  ) : (
    <ListBox field="calendarSlotId" placeholder="Choose empty slot" options={options} />
  );
}

SelectEmptySlot.propTypes = {
  field: yup.string().required().pt(),
  slot: SLOT_SCHEMA.pt(),
};

function makeEmptySlotListBoxOptions(slots) {
  return slots
    .map((slot, index) =>
      slot.status === 'Available' ? (
        <ListBox.Item key={slot.id}>
          <div className="flex flex-row items-center">
            <span className="ml-2 text-gray-900">
              Slot {index + 1} - <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
            </span>
          </div>
        </ListBox.Item>
      ) : null,
    )
    .filter((slot) => !!slot);
}
