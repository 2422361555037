import { useController } from 'react-hook-form';
import { yup } from '../../utils';
import { ErrorMessage } from './error-message';
import clsx from 'clsx';
import { Children, cloneElement } from 'react';

export function FieldDecoration({ field, label = '', hint = '', help = '', children }) {
  const { fieldState } = useController({ name: field });
  const { error } = fieldState;
  const child = cloneElement(Children.only(children), { decorated: true });
  return (
    <div className="relative">
      <div className={clsx('flex justify-between', label ? 'mb-1' : 'hidden')}>
        <label
          htmlFor={field}
          className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {label}
        </label>
        <span className="text-sm leading-6 text-gray-500">{hint}</span>
      </div>
      {child}
      <ErrorMessage error={error?.message} help={help} />
    </div>
  );
}

FieldDecoration.propTypes = {
  field: yup.string().required().pt(),
  label: yup.string().pt(),
  hint: yup.string().pt(),
  help: yup.string().pt(),
  children: yup.mixed().react().pt(),
};
