import { useFormContext } from 'react-hook-form';
import { dayjs } from '../utils';
import { EnumListBoxPlain } from './enum-list-box';
import { TextFieldPlain } from './form/text-field';

const OPTIONS_TO_EMIT = ['None', 'MovedToDifferentList'];

// This function has hardcode knowledge of the meaning of enum values in the
// API. Changes in the API need to be mirrored here!
export function determineDefaultCancellationTime(date) {
  const now = dayjs();
  const deltaHours = dayjs(date).diff(now, 'hours');
  if (deltaHours > 3 * 24) {
    return 'MoreThanThreeDaysBefore';
  } else if (deltaHours > 1 * 24) {
    return 'OneToThreeDaysBefore';
  } else if (deltaHours > 2) {
    return 'DayOf';
  } else {
    return 'OnAdmission';
  }
}

export function CancellationReasonFormFields() {
  const {
    formState: { isSubmitting, isLoading },
  } = useFormContext();
  return (
    <>
      <div className="w-40">
        <EnumListBoxPlain
          name="AppointmentCancellationTime"
          field="cancellationTime"
          placeholder="Choose time..."
          valuesToOmit={OPTIONS_TO_EMIT}
          disabled={isSubmitting || isLoading}
        />
      </div>
      <div className="w-48">
        <EnumListBoxPlain
          name="AppointmentCancellationReason"
          field="cancellationReason"
          placeholder="Choose reason..."
          valuesToOmit={OPTIONS_TO_EMIT}
          disabled={isSubmitting || isLoading}
        />
      </div>
      <div className="w-96">
        <TextFieldPlain
          field="cancellationNotes"
          placeholder="Enter comment ..."
          disabled={isSubmitting || isLoading}
        />
      </div>
    </>
  );
}
