import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from 'react-router';
import { StrictMode, Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { HotkeysProvider, useHotkeys } from 'react-hotkeys-hook';
import clsx from 'clsx';
import { MainNavigation } from '../lib/components/main-navigation';
import store from '../lib/api/store/store';
import { loginRoute } from './pages/login/login';
import { dashboardRoute } from './pages/dashboard/dashboard';
import { settingsRoute } from './pages/settings/settings';
import { profileRoute } from './pages/profile/profile';
import { debugRoute } from './pages/debug/debug';
import { yup } from '../lib/utils';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from './pages/loading';
import { useIsAuthenticated } from '../lib/api/store/authentication';
import '../lib/i18n';
import {
  SharedThunkDispatchManagerProvider,
  useSharedThunkDispatchManager,
} from '../lib/api/store/shared-thunk-dispatch-provider';
import { ToastPortal } from '../lib/components/toast';
import { usePreloadData } from '../lib/hooks/use-preload-data';
import { useUpdateChecker } from '../lib/hooks/use-update-checker';
import { IS_PRODUCTION, NOOP } from '../lib/constants';
import { ApplicationInsightsProvider } from '../lib/components/application-insights-provider';
import { useEnvironmentClassName } from '../lib/api/store/health';

const ROUTES = [
  dashboardRoute,
  //settingsRoute,
  //profileRoute
];

if (!IS_PRODUCTION) {
  ROUTES.push(debugRoute);
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <ApplicationInsightsProvider>
            <MainLayout />
          </ApplicationInsightsProvider>
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <Navigate to="dashboard" replace /> },
        dashboardRoute,
        settingsRoute,
        profileRoute,
        debugRoute,
      ],
      errorElement: <Navigate to="/" replace />,
    },
    loginRoute,
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
);

export function App() {
  return (
    <StrictMode>
      <ReduxProvider store={store}>
        <SharedThunkDispatchManagerProvider>
          <HotkeysProvider initiallyActiveScopes={['main']}>
            <ErrorBoundary onError={console.error}>
              <Suspense fallback={<Loading />}>
                <RouterProvider router={router} />
              </Suspense>
            </ErrorBoundary>
            <ToastPortal />
          </HotkeysProvider>
        </SharedThunkDispatchManagerProvider>
      </ReduxProvider>
    </StrictMode>
  );
}

function ProtectedRoute({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const params = new URLSearchParams({ redirect: location.pathname + location.search });
  const to =
    location.pathname?.length > 1 ? `/authentication?${params.toString()}` : '/authentication';
  return isAuthenticated ? children : <Navigate to={to} replace={true} />;
}

ProtectedRoute.propTypes = {
  children: yup.mixed().react().pt(),
};

function MainLayout() {
  const navigate = useNavigate();
  const { refresh } = useSharedThunkDispatchManager();
  const environmentClass = useEnvironmentClassName();
  usePreloadData();
  useUpdateChecker();
  useHotkeys('shift+d', () => (IS_PRODUCTION ? NOOP : navigate('/debug')));
  useHotkeys('shift+r', () => refresh());
  return (
    <div
      className={clsx(environmentClass, 'h-full flex flex-col bg-white overflow-scroll bg-cue')}
      data-testid="main-layout"
    >
      <MainNavigation />
      <main className="grow px-4 mb-4 overflow-hidden">
        <Outlet />
      </main>
    </div>
  );
}
