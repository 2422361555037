import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  INITIAL_THUNK_STATE,
  useStateWithSuspendFlag,
  createAsyncApiThunk,
  assignHashAndKeys,
  transformArrayToMapping,
  useSharedDispatch,
  setThunkStatePending,
  setThunkStateFulfilled,
  setThunkStateRejected,
} from './utils';
import { createSelector } from 'reselect';
import {
  transformProcedureCategory,
  transformProcedureCategoryGroup,
  transformProcedureCode,
} from './transformations';
import { yup } from '../../utils';
import { logger } from '../../logger';

const PROCEDURE_RESPONSE_SCHEMA = yup.array().of(
  yup.object({
    id: yup.string().guid().required(),
    definition: yup.string(),
    shortDisplay: yup.string().required(),
    procedureCategories: yup.array().of(
      yup.object({
        id: yup.string().guid().required(),
        definition: yup.string(),
        shortDisplay: yup.string().required(),
        procedureCodes: yup
          .array()
          .of(
            yup.object({
              id: yup.string().guid().required(),
              definition: yup.string(),
              shortDisplay: yup.string().required(),
              procedurePoints: yup.number().required(),
            }),
          )
          .required(),
      }),
    ),
  }),
);

export const fetchProcedures = createAsyncApiThunk('procedures/fetch', async (_, { apiPost }) => {
  const rawProcedureCategoryGroups = await apiPost('procedure/categorygroupsearch', {
    searchTerm: '',
  });
  logger.info(`Received ${rawProcedureCategoryGroups.length} procedure category groups`);
  PROCEDURE_RESPONSE_SCHEMA.validateSync(rawProcedureCategoryGroups);
  const result = transformArrayToMapping(
    rawProcedureCategoryGroups.map((rawProcedureCategoryGroup) =>
      assignHashAndKeys(
        {
          ...rawProcedureCategoryGroup,
          procedureCategories: transformArrayToMapping(
            rawProcedureCategoryGroup.procedureCategories.map((rawProcedureCategory) => ({
              ...rawProcedureCategory,
              procedureCodes: transformArrayToMapping(rawProcedureCategory.procedureCodes),
            })),
          ),
        },
        extractKeysFromProcedureCategory,
      ),
    ),
  );
  return result;
});

const proceduresSlice = createSlice({
  name: 'procedures',
  initialState: { ...INITIAL_THUNK_STATE },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProcedures.pending, (state) => {
        setThunkStatePending(state);
      })
      .addCase(fetchProcedures.fulfilled, (state, action) => {
        setThunkStateFulfilled(state, action.payload);
      })
      .addCase(fetchProcedures.rejected, (state, action) => {
        setThunkStateRejected(state, action.error);
      });
  },
});

export default proceduresSlice.reducer;

// Generate a three way view of procedure data once to speed up the various
// uses. The views are:
// {
//   byGroup: { groupId: { ...group, procedureCategories} },
//   byCategory: { categoryId: { ...category, procedureCodes, procedureCategoryGroup} },
//   byCode: { codeId: { ...code, procedureCategory } }
//}
const proceduresDataSelector = createSelector([(state) => state.procedures], (slice) => {
  const data = {
    byGroup: Object.values(slice.data).reduce((acc, rawCategoryGroup) => {
      const { procedureCategories: rawCategories, ...rawCategoryWithoutCategories } =
        rawCategoryGroup;
      const categoryGroup = transformProcedureCategoryGroup(rawCategoryWithoutCategories);
      categoryGroup.procedureCategories = Object.values(rawCategories).reduce(
        (acc, rawProcedureCategory) => {
          const { procedureCodes: rawCodes, ...rawCategoryWithoutCodes } = rawProcedureCategory;
          const category = transformProcedureCategory(rawCategoryWithoutCodes);
          category.procedureCodes = Object.values(rawCodes).reduce((acc, rawCode) => {
            const code = transformProcedureCode(rawCode);
            code.procedurePoints = category.procedurePoints;
            code.procedureCategoryId = category.id;
            acc[code.id] = code;
            return acc;
          }, {});
          acc[category.id] = category;
          return acc;
        },
        {},
      );
      acc[categoryGroup.id] = categoryGroup;
      return acc;
    }, {}),
  };

  data.byCategory = Object.values(data.byGroup).reduce(
    (acc, { procedureCategories, $hash, $keys, ...procedureCategoryGroup }) => {
      for (const category of Object.values(procedureCategories)) {
        acc[category.id] = { ...category, procedureCategoryGroup };
      }
      return acc;
    },
    {},
  );

  data.byCode = Object.values(data.byCategory).reduce(
    (acc, { procedureCodes, ...procedureCategory }) => {
      for (const code of Object.values(procedureCodes)) {
        acc[code.id] = { ...code, procedureCategory };
      }
      return acc;
    },
    {},
  );

  return { ...slice, data };
});

function useProceduresState(byGrouping) {
  useSharedDispatch(fetchProcedures, null, { pollInterval: 24 * 3600 * 1000 });
  const state = useSelector(proceduresDataSelector);
  return useStateWithSuspendFlag({ ...state, data: state.data[byGrouping] });
}

export function useProcedureCodesState() {
  return useProceduresState('byCode');
}

export function useProcedureCode(procedureCodeId) {
  const state = useProcedureCodesState();
  return state.data[procedureCodeId] ?? null;
}

export function useProcedureCategoriesState() {
  return useProceduresState('byCategory');
}

export function useProcedureCategory(procedureCategoryId) {
  const state = useProcedureCategoriesState();
  return state.data[procedureCategoryId] ?? null;
}

export function useProcedureCategoriesGroupState() {
  return useProceduresState('byGroup');
}

export function useProcedureCategoryGroup(procedureCategoryGroupId) {
  const state = useProcedureCategoriesGroupState();
  return state.data[procedureCategoryGroupId] ?? null;
}

function extractKeysFromProcedureCategory(procedureCategoryGroup) {
  const keys = {};
  for (const procedureCategory of Object.values(procedureCategoryGroup.procedureCategories)) {
    keys[procedureCategory.id] = `procedureCategory[${procedureCategory.id}]`;
    for (const procedureCodeId of Object.keys(procedureCategory.procedureCodes)) {
      keys[procedureCodeId] =
        `procedureCategory[${procedureCategory.id}].procedureCodes[${procedureCodeId}]`;
    }
  }
  return keys;
}
