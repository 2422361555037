import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { yup } from '../../utils';
import { Tooltip } from '../tooltip';

export function FilledNotes({ notes }) {
  return notes ? (
    <Tooltip>
      <Tooltip.Trigger>
        <ChatBubbleBottomCenterTextIcon className="size-5" />
      </Tooltip.Trigger>
      <Tooltip.Content>{notes}</Tooltip.Content>
    </Tooltip>
  ) : null;
}

FilledNotes.propTypes = {
  notes: yup.string().pt(),
};
