import { useFormContext } from 'react-hook-form';
import { yup } from '../../utils';
import { EMPTY_OBJECT } from '../../constants';
import { ErrorIcon } from './error-message';
import clsx from 'clsx';
import { FieldDecoration } from './field-decoration';

export function TextareaFieldPlain({
  field,
  placeholder = '',
  disabled = false,
  decorated = false,
  autocomplete = undefined,
}) {
  const { register, formState } = useFormContext();
  const { errors } = formState;
  const error = errors[field]?.message;
  const textareaProps = field ? register(field) : EMPTY_OBJECT;

  return (
    <div className="relative rounded-md shadow-sm">
      <textarea
        {...textareaProps}
        id={field}
        className={clsx(
          'block w-full rounded-md border-0 h-16 py-1.5 shadow-sm ring-1 focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6',
          error
            ? 'text-error-500 border-error-500 placeholder:text-error-300 ring-error-500 focus:ring-error-500'
            : 'text-gray-900 border-gray-300 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-500 focus:border-indigo-500',
        )}
        placeholder={placeholder}
        disabled={disabled}
        aria-invalid={!!error}
        autoComplete={autocomplete}
      />
      <ErrorIcon error={!decorated ? error : undefined} className="max-h-10" />
    </div>
  );
}

TextareaFieldPlain.propTypes = {
  field: yup.string().required().pt(),
  placeholder: yup.string().pt(),
  disabled: yup.boolean().pt(),
  decorated: yup.boolean().pt(),
  autocomplete: yup.string().pt(),
};

export function TextareaField(props) {
  return (
    <FieldDecoration {...props}>
      <TextareaFieldPlain {...props} />
    </FieldDecoration>
  );
}
