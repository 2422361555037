import { FormProvider, useForm } from 'react-hook-form';
import { yup } from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Td, TdSlotNumber } from './shared';
import { Button } from '../button';
import { useAddExtraSlot } from '../../api/actions/add-extra-slot';
import { TimeField } from '../form/time-field';
import clsx from 'clsx';
import { useGenericErrorsToast } from '../toast';

const TD_CLASSNAMES = 'text-gray-700 font-normal text-sm px-3 py-2';

const EXTRA_SLOT_FORM_SCHEMA = yup.object().shape({
  arrivalTime: yup.string().required(),
});

export function ExtraSlotRow({ index, listId, initialValue, removeSlot }) {
  const extraSlotForm = useForm({
    resolver: yupResolver(EXTRA_SLOT_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: { arrivalTime: initialValue },
  });
  const { isSubmitting, isValid, isLoading } = extraSlotForm.formState;
  const disabled = !isValid || isSubmitting || isLoading;
  const genericErrorsToast = useGenericErrorsToast();

  const addExtraSlotState = useAddExtraSlot();

  const onSubmit = useCallback(
    async (values) => {
      try {
        await addExtraSlotState.execute({ listId, arrivalTime: values.arrivalTime + ':00' });
        removeSlot();
      } catch (error) {
        genericErrorsToast('Failed to add slot to list', error);
      }
    },
    [addExtraSlotState, genericErrorsToast, listId, removeSlot],
  );

  return (
    <tr className="group" data-testid={`slot-${index}`} data-slot-type="extra-edit">
      <TdSlotNumber index={index} />
      <Td className={clsx(TD_CLASSNAMES, 'font-semibold')} colSpan={11}>
        <FormProvider {...extraSlotForm}>
          <form onSubmit={extraSlotForm.handleSubmit(onSubmit)} className="m-0 flex gap-x-2">
            <TimeField field="arrivalTime" disabled={disabled} />
            <Button label="Save" type="submit" className="min-w-16" disabled={disabled} />
          </form>
        </FormProvider>
      </Td>
      <Td className="pr-3">
        <div className="flex justify-end items-center">
          <Button icon={XMarkIcon} variant="plain" labelClassName="sr-only" onClick={removeSlot} />
        </div>
      </Td>
    </tr>
  );
}

ExtraSlotRow.propTypes = {
  index: yup.number().required().pt(),
  listId: yup.string().guid().pt(),
  initialValue: yup.string().pt(),
  removeSlot: yup.mixed().callback().pt(),
};
