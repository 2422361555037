import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  Td,
  TdArrivalTime,
  TdSlotNumber,
  APPOINTMENT_STATUSES,
  APPOINTMENT_STATUSES_TO_OMIT,
} from './shared';
import { useCallback } from 'react';
import { useUpdateAppointment } from '../../api/actions/save-appointment';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '../form/text-field';
import { Button } from '../button';
import { EnumListBoxPlain } from '../enum-list-box';
import { ProcedureComboBox } from '../procedure-combo-box';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { useGenericErrorsToast } from '../toast';
import { PatientSearchForm } from './patient-search-form';
import { PatientDetailsFormFields } from './patient-details-form-fields';
import { APPOINTMENT_FORM_SCHEMA } from './appointment-form-schema';

export function FilledSlotRowEdit({ index, slot, setMode, initialAppointmentStatus }) {
  const editAppointmentForm = useForm({
    resolver: yupResolver(APPOINTMENT_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: {
      patientId: slot.appointment.patientId ?? undefined,
      nhiNumber: slot.appointment.patient?.nhiNumber ?? '',
      nameGiven: slot.appointment.patient?.nameGiven ?? '',
      nameFamily: slot.appointment.patient?.nameFamily ?? '',
      email: slot.appointment.patient?.email ?? '',
      phoneNumber: slot.appointment.patient?.phoneNumber ?? '',
      referralSource: slot.appointment.referralSource,
      procedureCodeId: slot.appointment.procedure.procedureCode.id,
      appointmentStatus: initialAppointmentStatus
        ? initialAppointmentStatus
        : slot.appointment.status,
      appointmentNote: slot.appointment.notes,
    },
  });
  const { isSubmitting, isSubmitted, isValid, isDirty, isLoading } = editAppointmentForm.formState;
  const disabled = isSubmitting || isSubmitted || isLoading;
  const genericErrorsToast = useGenericErrorsToast();
  const patientId = editAppointmentForm.watch('patientId');

  const updateAppointment = useUpdateAppointment();
  const updateAppointmentHandler = useCallback(
    async (values) => {
      try {
        await updateAppointment.execute({
          calendarSlotId: slot.id,
          appointmentId: slot.appointment.id,
          patientId: values.patientId,
          nhiNumber: values.nhiNumber,
          nameGiven: values.nameGiven,
          nameFamily: values.nameFamily,
          email: values.email,
          phoneNumber: values.phoneNumber,
          referralSource: values.referralSource,
          procedureCodeId: values.procedureCodeId,
          appointmentNote: values.appointmentNote,
          appointmentStatus: values.appointmentStatus,
          isEnteredInWebPas: false,
        });
        setMode(0);
      } catch (error) {
        genericErrorsToast('Failed to update appointment', error);
      }
    },
    [genericErrorsToast, setMode, slot.appointment.id, slot.id, updateAppointment],
  );

  editAppointmentForm.watch(({ appointmentStatus }, { name, type }) => {
    if (name === 'appointmentStatus' && type === 'change') {
      if (appointmentStatus === APPOINTMENT_STATUSES.ENTERED_IN_ERROR) {
        setMode(2);
      } else if (appointmentStatus === APPOINTMENT_STATUSES.CANCELLED) {
        setMode(4);
      }
    }
  });

  return (
    <FormProvider {...editAppointmentForm}>
      <tr data-testid={`slot-${index}`} data-slot-type="filled-edit">
        <TdSlotNumber index={index} className="bg-gray-50" />
        <TdArrivalTime slot={slot} className="bg-gray-50" />
        <Td className="bg-gray-50">
          <EnumListBoxPlain
            name="AppointmentStatus"
            field="appointmentStatus"
            placeholder="Status"
            valuesToOmit={APPOINTMENT_STATUSES_TO_OMIT}
            disabled={disabled}
          />
        </Td>
        <Td className="bg-gray-50">
          <ProcedureComboBox field="procedureCodeId" disabled={disabled} />
        </Td>
        <Td className="bg-gray-50">
          <ChatBubbleBottomCenterTextIcon className="size-5 inline-block" />
          <div className="border-solid border-b-gray-600 border-b-[length:10] border-x-transparent border-x-[length:8] border-t-0 absolute right-1/2 transform translate-x-1/2 translate-y-[1px] bottom-0 z-11"></div>
          <div className="border-solid border-b-white border-b-[length:10] border-x-transparent border-x-[length:8] border-t-0 absolute right-1/2 transform translate-x-1/2 translate-y-[1px] bottom-0 z-10"></div>
        </Td>
        {patientId === undefined ? (
          <PatientSearchForm
            disabled={disabled}
            setFormField={(k, v) =>
              editAppointmentForm.setValue(k, v, { shouldValidate: true, shouldDirty: true })
            }
          />
        ) : (
          <PatientDetailsFormFields />
        )}
        <Td className="bg-gray-50">
          <EnumListBoxPlain
            name="ReferralSource"
            field="referralSource"
            placeholder="Source"
            disabled={disabled}
          />
        </Td>
        <Td colSpan={2} className="text-center bg-gray-50 pr-3">
          <div className="flex gap-x-1 items-center justify-end">
            <form
              onSubmit={editAppointmentForm.handleSubmit(updateAppointmentHandler)}
              className="m-0"
            >
              <Button
                type="submit"
                variant="plain"
                label="SAVE"
                disabled={disabled || !isValid || !isDirty}
              />
            </form>
            <Button
              icon={XMarkIcon}
              variant="plain"
              labelClassName="sr-only"
              onClick={() => setMode(0)}
              disabled={disabled}
            />
          </div>
        </Td>
      </tr>
      <tr>
        <td colSpan="4" className="bg-gray-50"></td>
        <td colSpan="7" className="bg-gray-50">
          <div className="w-full inline-block mb-3">
            <TextField field="appointmentNote" placeholder="Notes..." disabled={disabled} />
          </div>
        </td>
        <td colSpan="2" className="bg-gray-50"></td>
      </tr>
    </FormProvider>
  );
}

FilledSlotRowEdit.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
  initialAppointmentStatus: yup.string().nullable().pt(),
};
