import { XMarkIcon } from '@heroicons/react/24/outline';
import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import {
  Td,
  TdArrivalTime,
  TdSlotNumber,
  APPOINTMENT_STATUSES,
  APPOINTMENT_STATUSES_TO_OMIT,
} from './shared';
import { useCallback } from 'react';
import { EnumListBox } from '../enum-list-box';
import { Button } from '../button';
import { useCancelAppointmentState } from '../../api/actions/cancel-appointment';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckBox } from '../form/check-box';
import { useGenericErrorsToast } from '../toast';
import {
  CancellationReasonFormFields,
  determineDefaultCancellationTime,
} from '../cancellation-reason-form-fields';
import { useChange } from '../../hooks/use-change';

function FilledSlotRowRemove({
  index,
  slot,
  setMode,
  setInitialAppointmentStatus,
  cancelAppointmentLabel = 'CANCEL APPOINTMENT',
  children,
}) {
  const appointmentStatus = useWatch({ name: 'appointmentStatus' });
  useChange(appointmentStatus, (current) => {
    if (current === APPOINTMENT_STATUSES.ENTERED_IN_ERROR) {
      setMode(2);
    } else if (current === APPOINTMENT_STATUSES.CANCELLED) {
      setMode(4);
    } else {
      setInitialAppointmentStatus(current);
      setMode(1);
    }
  });

  const genericErrorsToast = useGenericErrorsToast();

  const { handleSubmit, formState } = useFormContext();
  const { isSubmitting, isValid, isLoading } = formState;
  const formDisabled = !isValid || isSubmitting || isLoading;

  const cancelAppointment = useCancelAppointmentState();
  const onSubmit = useCallback(
    async ({ appointmentStatus, cancellationTime, cancellationReason, cancellationNotes }) => {
      try {
        await cancelAppointment.execute({
          appointmentId: slot.appointment.id,
          cancellationStatus: appointmentStatus,
          cancellationTime: cancellationTime ?? 'None',
          cancellationReason: cancellationReason ?? 'None',
          cancellationNotes: cancellationNotes ?? '',
        });
        setMode(0);
      } catch (error) {
        genericErrorsToast('Failed to cancel/delete appointment', error);
      }
    },
    [cancelAppointment, genericErrorsToast, setMode, slot.appointment.id],
  );

  return (
    <tr
      className="border-t border-gray-200 bg-red-50"
      data-testid={`slot-${index}`}
      data-slot-type="filled-remove"
    >
      <TdSlotNumber index={index} />
      <TdArrivalTime slot={slot} />
      <Td>
        <EnumListBox
          name="AppointmentStatus"
          field="appointmentStatus"
          placeholder="Status"
          valuesToOmit={APPOINTMENT_STATUSES_TO_OMIT}
        />
      </Td>
      <Td colSpan={9}>
        <div className="flex gap-x-4 items-center">
          {children}
          <CheckBox
            field="webpasChecked"
            label="Removed from WebPAS"
            disabled={isSubmitting || isLoading}
            testId="webpasSync"
          ></CheckBox>
          <Button
            label={cancelAppointmentLabel}
            variant="plain"
            color="red"
            type="button"
            className="min-w-16"
            disabled={formDisabled}
            onClick={() => handleSubmit(onSubmit)()}
            testId="removeAppointment"
          />
        </div>
      </Td>
      <Td className="flex justify-end items-center pr-3">
        <Button
          icon={XMarkIcon}
          variant="plain"
          labelClassName="sr-only"
          onClick={() => setMode(0)}
        />
      </Td>
    </tr>
  );
}

FilledSlotRowRemove.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
  setInitialAppointmentStatus: yup.mixed().callback().pt(),
  cancelAppointmentLabel: yup.string().pt(),
  children: yup.mixed().react().required().pt(),
};

const ENTERED_IN_ERROR_FORM_SCHEMA = yup.object().shape({
  appointmentStatus: yup.string().required(),
  webpasChecked: yup.boolean().oneOf([true]).required(),
});

export function FilledSlotRowEnteredInError(props) {
  const enteredInErrorAppointmentForm = useForm({
    resolver: yupResolver(ENTERED_IN_ERROR_FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: {
      appointmentStatus: APPOINTMENT_STATUSES.ENTERED_IN_ERROR,
      webpasChecked: false,
    },
  });

  return (
    <FormProvider {...enteredInErrorAppointmentForm}>
      <FilledSlotRowRemove
        {...props}
        cancelAppointmentStatus={APPOINTMENT_STATUSES.ENTERED_IN_ERROR}
      >
        <div className="content-center italic">
          This status change is permanent, and will delete this appointment from this list. Are you
          sure?
        </div>
      </FilledSlotRowRemove>
    </FormProvider>
  );
}

const CANCEL_APPOINTMENT_FORM_SCHEMA = yup.object().shape({
  appointmentStatus: yup.string().required(),
  cancellationTime: yup.string().required(),
  cancellationReason: yup.string().required(),
  cancellationNotes: yup.string().max(128),
  webpasChecked: yup.boolean().oneOf([true]).required(),
});

export function FilledSlotRowCancel(props) {
  const cancelAppointmentForm = useForm({
    resolver: yupResolver(CANCEL_APPOINTMENT_FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: {
      appointmentStatus: APPOINTMENT_STATUSES.CANCELLED,
      cancellationTime: determineDefaultCancellationTime(props.slot?.arrivalTimeTimestamp),
      cancellationReason: '',
      cancellationNotes: '',
      webpasChecked: false,
    },
  });

  return (
    <FormProvider {...cancelAppointmentForm}>
      <FilledSlotRowRemove {...props} cancelAppointmentStatus={APPOINTMENT_STATUSES.CANCELLED}>
        <CancellationReasonFormFields />
      </FilledSlotRowRemove>
    </FormProvider>
  );
}

FilledSlotRowCancel.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};
